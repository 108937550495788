"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class VerifyCodeApi {
  /**
   * 发送验证码
   * @param vo
   */
  sendCode(vo) {
    return __async(this, null, function* () {
      return yield API.post(
        `api://base/v1/verification-codes`,
        vo
      );
    });
  }
  /**
   * 验证码检验
   * @param vo
   */
  checkCode(vo) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/check-email`,
        vo
      );
    });
  }
}
export const verifyCodeApi = new VerifyCodeApi();
