"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { DataSetModel } from "@/data/models/dataset.model";
import { Annotation, CoreModel } from "@ym-framework/model";
export let Page = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.current = 1;
    this.total = 0;
    this.size = 0;
  }
};
Page.model = "page";
Page = __decorateClass([
  Annotation.ModelMeta
], Page);
export let GridModel = class extends CoreModel {
  constructor() {
    super(...arguments);
    this.result = new DataSetModel();
    this.page = new Page();
  }
};
GridModel.model = "grid";
GridModel = __decorateClass([
  Annotation.ModelMeta
], GridModel);
