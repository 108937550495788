"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
let ExportProxyFormatDialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialogVisible = false;
    this.ids = [];
    this.dialogLoading = false;
    this.chooseHeaderType = "has";
    this.chooseUrlFormat = "one";
    this.chooseProtocolType = "http";
  }
  open(rows) {
    this.ids = _.map(rows, "id");
    this.dialogVisible = true;
  }
  close() {
    this.dialogVisible = false;
  }
  cancel() {
    this.close();
  }
  exportConfirm() {
    let exportData = {
      ids: this.ids,
      protocol: this.chooseHeaderType === "has" ? true : false,
      format: this.chooseUrlFormat === "one" ? true : false,
      socks: this.chooseProtocolType === "socks" ? true : false
    };
    this.$emit("exportProxy", { data: exportData });
    this.close();
  }
};
ExportProxyFormatDialog = __decorateClass([
  Component({
    components: {}
  })
], ExportProxyFormatDialog);
export {
  ExportProxyFormatDialog as default
};
