"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class ProductApi {
  /**
   * 获取商品列表
   * @param vo
   */
  getProduct(vo) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/products`,
        {
          params: vo
        }
      );
      return response.data;
    });
  }
  /**
   * 获取可显示的支付方式
   */
  getPaymentMethods() {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base.auth/v1/payments`);
      return response.data;
    });
  }
  /**
   *	获取此region下的地域信息 
   */
  getRegionDatas(id) {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base.auth/v1/dynamic-house/regions/${id}`);
      return response.data;
    });
  }
}
export const productApi = new ProductApi();
