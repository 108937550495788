"use strict";
import Vue from "vue";
import VueRouter from "vue-router";
import { AuthAccess, GuestAccess } from "@/shared/services/access-rules/access-rules.service";
import Auth from "@/views/auth/views/Auth.vue";
import Payment from "@/views/payment/views/Payment.vue";
import ProxyManagement from "@/views/proxyManagement/views/ProxyManagement.vue";
import CostManagement from "@/views/costManagement/views/CostManagement.vue";
import AccountManagement from "@/views/accountManagement/views/AccountManagement.vue";
import PaymentConfirm from "@/views/payment/views/PaymentConfirm.vue";
import Renew from "@/views/payment/views/Renew.vue";
import TrafficManagement from "@/views/trafficManagement/views/TrafficManagement.vue";
import TrafficPackage from "@/views/payment/views/TrafficPackage.vue";
import AccountBinding from "@/views/accountBinding/views/AccountBinding.vue";
import USDTPaymentConfigure from "@/views/costManagement/views/USDTPaymentConfigure.vue";
import LayoutMain from "@/components/LayoutMain.vue";
import PaymentSuccess from "@/views/payment/views/components/PaymentSuccess.vue";
import LayoutManagement from "@/components/Layout.vue";
import RegisterSuccess from "@/views/auth/views/RegisterSuccess.vue";
import AppManagement from "@/views/appManagement/views/AppManagement.vue";
import AppPayment from "@/views/appPayment/views/AppPayment.vue";
import RegisterNewSuccess from "@/views/main/views/RegisterNewSuccess.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/auth",
    name: "auth",
    meta: {
      title: "ip_rocket"
    },
    component: Auth
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: "ip_rocket"
    },
    component: Auth
  },
  {
    path: "/register_success",
    name: "register_success",
    meta: {
      title: "ip_rocket"
    },
    component: RegisterSuccess
  },
  {
    path: "/auth_success",
    name: "auth_success",
    meta: {
      title: "ip_rocket"
    },
    component: RegisterNewSuccess
  },
  {
    path: "/index",
    name: "index",
    meta: {
      title: "ip_rocket"
    },
    component: LayoutMain
  },
  {
    path: "/",
    name: "iprocket",
    meta: {
      title: "ip_rocket"
    },
    component: LayoutMain
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/proxy",
    // meta: {
    //   access:new AuthAccess()
    // },
    component: LayoutManagement,
    children: [
      {
        path: "payment",
        name: "payment",
        meta: {
          access: new AuthAccess(),
          title: "buying_proxy",
          menuactive: "1"
        },
        component: Payment
      },
      {
        path: "renew",
        name: "renew",
        meta: {
          access: new AuthAccess(),
          title: "renewal_proxy",
          menuactive: "1"
        },
        component: Renew
      },
      {
        path: "traffic",
        name: "traffic",
        meta: {
          access: new AuthAccess(),
          title: "buying_traffic_package",
          menuactive: "1"
        },
        component: TrafficPackage
      },
      {
        path: "confirm",
        name: "confirm",
        meta: {
          access: new AuthAccess(),
          title: "order_check_and_payment"
        },
        component: PaymentConfirm
      },
      {
        path: "proxy",
        name: "proxy",
        meta: {
          access: new AuthAccess(),
          title: "proxy_management",
          menuactive: "2"
        },
        component: ProxyManagement
      },
      {
        path: "traffics",
        name: "traffics",
        meta: {
          access: new AuthAccess(),
          title: "traffic_management",
          menuactive: "2"
        },
        component: TrafficManagement
      },
      {
        path: "cost",
        name: "cost",
        meta: {
          access: new AuthAccess(),
          title: "cost_center",
          menuactive: "3"
        },
        component: CostManagement
      },
      {
        path: "pay_order",
        name: "payOrder",
        meta: {
          access: new AuthAccess(),
          title: "pay_order"
        },
        component: USDTPaymentConfigure
      },
      {
        path: "account",
        name: "account",
        meta: {
          access: new AuthAccess(),
          title: "account_settings",
          menuactive: "4"
        },
        component: AccountManagement
      },
      {
        path: "app",
        name: "app",
        meta: {
          access: new AuthAccess(),
          title: "app_management",
          menuactive: "5"
        },
        component: AppManagement
      },
      {
        path: "app_pay",
        name: "appPay",
        meta: {
          access: new AuthAccess(),
          title: "buying_app",
          menuactive: "6"
        },
        component: AppPayment
      }
      // {
      //   path:"paySuccess",
      //   name:"paySuccess",
      //   meta:{
      //     access:new AuthAccess(),
      //     title:'ip_rocket'
      //   },
      //   component: PaymentSuccess
      // }
    ]
  },
  // 接入CB进入后中转页面
  {
    path: "/bindAccount",
    name: "bindAccount",
    meta: {
      title: "account_binding"
    },
    component: AccountBinding
  },
  {
    path: "*",
    name: "*",
    meta: {
      title: "ip_rocket"
    },
    redirect: (to) => {
      return { path: "/" };
    }
  },
  // 一下部分router为针对匿名用户的购买页面、订单确认页面以及绑定页面
  // 游客layout
  // 购买选商品页面
  // {
  //   path:"/payments",
  //   name:"Payments",
  //   meta:{
  //     title:'buying_proxy'
  //   },
  //   component:GuesterPayments
  // },
  // // 订单详情、确认支付页面
  // {
  //   path:"/orderConfirm",
  //   name:'orderConfirm',
  //   meta:{
  //     access:new GhostAccess(),
  //     title:'order_check_and_payment'
  //   },
  //   component:GuesterPaymentsConfirm
  // },
  // 购买成功。绑定IPRockets账号页面
  {
    path: "/paySuccess",
    name: "PaySuccess",
    meta: {
      access: new GuestAccess(),
      title: "ip_rocket"
    },
    component: PaymentSuccess
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  var _a;
  let _access = new GuestAccess();
  if ((_a = to.meta) == null ? void 0 : _a.access) {
    _access = to.meta.access;
  }
  _access.next(to, from, next);
});
export default router;
