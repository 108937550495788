"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
let AppPaymentCost = class extends Vue {
  constructor() {
    super(...arguments);
    // 是否勾选同意协议
    this.isAgree = true;
    this.showTooltip = false;
    // 显示余额不足弹窗
    this.showChargeTips = false;
    // 支付按钮显示可支付状态
    this.showButtonCanUse = false;
    //用户信息
    this.accountInfo = AccountInfoService.getAccountInfo();
  }
  // 创建订单
  buildOrder() {
    if (this.type !== "traffic" && !this.isAgree) {
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 3e3);
      return;
    }
    this.$emit("buildOrder");
  }
  // 跳转余额充值页面
  toChargeBalance() {
    this.$router.push("/admin/cost");
  }
  // 取消显示余额不足提示
  cancelTips() {
    this.showChargeTips = false;
  }
  goToTerms() {
    window.open("https://www.iprockets.net/terms/");
  }
};
__decorateClass([
  Prop()
], AppPaymentCost.prototype, "paymentCost", 2);
__decorateClass([
  Prop()
], AppPaymentCost.prototype, "type", 2);
AppPaymentCost = __decorateClass([
  Component({
    components: {}
  })
], AppPaymentCost);
export {
  AppPaymentCost as default
};
