"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
let RegisterNewSuccess = class extends Vue {
  constructor() {
    super(...arguments);
    this.jump_timeout = 5;
  }
  mounted() {
    return __async(this, null, function* () {
      this.checkJump();
    });
  }
  jumptoTelegram() {
    window.open("https://t.me/iprockets01", "_blank");
  }
  checkJump() {
    let timeout_timer = setInterval(() => {
      if (this.jump_timeout >= 0) {
        this.jump_timeout = this.jump_timeout - 1;
      }
      if (this.jump_timeout < 0) {
        clearInterval(timeout_timer);
        this.jump_timeout = 5;
        this.toSuccessPage();
      }
    }, 1e3);
  }
  toSuccessPage() {
    this.$router.push("/auth");
  }
};
RegisterNewSuccess = __decorateClass([
  Component({
    components: {}
  })
], RegisterNewSuccess);
export {
  RegisterNewSuccess as default
};
