"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import { PaymentConfirmService } from "@/views/payment/services/payment-confirm.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { OrderType, PayPlatformType } from "@/shared/services/payment/order.service";
let PaymentConfirm = class extends Vue {
  constructor() {
    super(...arguments);
    this.paymentConfirmService = new PaymentConfirmService();
    this.orderInfo = this.$route.query;
    this.orderType = OrderType;
    this.payPlatformType = PayPlatformType;
    this.loading = false;
  }
  created() {
    return __async(this, null, function* () {
      yield this.getOrderDetail();
    });
  }
  getOrderDetail() {
    return __async(this, null, function* () {
      try {
        yield this.paymentConfirmService.getOrderDetail(this.orderInfo.o_no);
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  completeOrder() {
    return __async(this, null, function* () {
      try {
        this.loading = true;
        yield this.paymentConfirmService.completeOrder(this.orderInfo.o_no);
        if (this.paymentConfirmService.orderDetail.type === "trafficPackage") {
          yield this.$router.push("/admin/traffics");
        } else if (this.paymentConfirmService.orderDetail.type === "VPS") {
          yield this.$router.push("/admin/app");
        } else {
          yield this.$router.push("/admin");
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.loading = false;
      }
    });
  }
  cancelOrder() {
    return __async(this, null, function* () {
      try {
        this.loading = true;
        yield this.paymentConfirmService.cancelOrder(this.orderInfo.o_no);
        this.$router.go(-1);
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.loading = false;
      }
    });
  }
};
PaymentConfirm = __decorateClass([
  Component({
    components: {}
  })
], PaymentConfirm);
export {
  PaymentConfirm as default
};
