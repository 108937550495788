"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { UiFormController, UiFormStatus, UiFormAnnotations } from "@/components/form/UiForm.vue";
import { verifyCodeApi } from "@/shared/api/verify-code/verify-code.api";
import { Message } from "element-ui";
import { accountManagementApi } from "@/data/api/account-management/account-management.api";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import i18n from "@/lang";
export var AccountInfoDialogType = /* @__PURE__ */ ((AccountInfoDialogType2) => {
  AccountInfoDialogType2["UNKNOWN"] = "";
  AccountInfoDialogType2["BIND_EMAIL"] = "bindEmail";
  AccountInfoDialogType2["CHANGE_EMAIL"] = "changeEmail";
  AccountInfoDialogType2["BIND_CB"] = "bindCB";
  AccountInfoDialogType2["CHANGE_CB"] = "changeCB";
  AccountInfoDialogType2["BIND_PAY_PAL"] = "bindPayPal";
  AccountInfoDialogType2["CHANGE_PAY_PAL"] = "changePayPal";
  AccountInfoDialogType2["SET_PASSWORD"] = "setPassword";
  AccountInfoDialogType2["CHANGE_PASSWORD"] = "changePassword";
  AccountInfoDialogType2["SWITCH_LANGUAGE"] = "switchLanguage";
  return AccountInfoDialogType2;
})(AccountInfoDialogType || {});
export class AccountInfoDialogService {
  constructor() {
    /// 账号信息
    this.accountInfo = AccountInfoService.getAccountInfo();
    /// 弹窗标题
    this.title = "";
    /// 表单数据
    this.formController = new UiFormController();
    /// 是否显示表格确认按钮
    this.showConfirm = true;
  }
  /**
   * 获取表单类型
   * @param type
   */
  getType(type) {
    switch (type) {
      case "bindEmail" /* BIND_EMAIL */:
        this.title = i18n.t("account.email.bind.title");
        this.showConfirm = true;
        this.formController = new EmailController();
        return;
      case "changeEmail" /* CHANGE_EMAIL */:
        this.title = i18n.t("account.email.change.title");
        this.showConfirm = false;
        this.formController = new EmailController();
        return;
      case "bindCB" /* BIND_CB */:
        this.showConfirm = true;
        this.formController = new CBController();
        return;
      case "changeCB" /* CHANGE_CB */:
        this.showConfirm = false;
        this.formController = new CBController();
        return;
      case "bindPayPal" /* BIND_PAY_PAL */:
        this.title = i18n.t("account.pay_pal.bind.title");
        this.showConfirm = true;
        this.formController = new PayPalController();
        return;
      case "changePayPal" /* CHANGE_PAY_PAL */:
        this.title = i18n.t("account.pay_pal.change.title");
        this.showConfirm = false;
        this.formController = new PayPalController();
        return;
      case "setPassword" /* SET_PASSWORD */:
        this.showConfirm = false;
        this.formController = new PasswordController();
        return;
      case "changePassword" /* CHANGE_PASSWORD */:
        this.title = i18n.t("account.password.change.title");
        this.showConfirm = false;
        this.formController = new PasswordController();
        return;
      case "switchLanguage" /* SWITCH_LANGUAGE */:
        this.title = i18n.t("account.language.switch.title");
        this.formController = new LanguageController();
        return;
      default:
        return;
    }
  }
  /**
   * 提交表单
   * @param type
   */
  submit(type) {
    return __async(this, null, function* () {
      switch (type) {
        case "bindEmail" /* BIND_EMAIL */:
          return "";
        case "changeEmail" /* CHANGE_EMAIL */:
          if (!this.showConfirm) {
            try {
              yield this.checkVerifyCode();
              this.formController = new EmailController();
              this.showConfirm = true;
            } catch (error) {
              throwErrorApi.throwError(error);
            } finally {
              this.formController.status = UiFormStatus.COMPLETE;
            }
            return "";
          } else {
            return "bindEmail";
          }
        case "bindCB" /* BIND_CB */:
          return "";
        case "changeCB" /* CHANGE_CB */:
          this.formController = new CBController();
          this.showConfirm = true;
          return "";
        case "bindPayPal" /* BIND_PAY_PAL */:
          return "bindPaypalEmail";
        case "changePayPal" /* CHANGE_PAY_PAL */:
          if (!this.showConfirm) {
            try {
              yield this.checkVerifyCode(this.showConfirm);
              this.formController = new PayPalController();
              this.showConfirm = true;
            } catch (error) {
              throwErrorApi.throwError(error);
            } finally {
              this.formController.status = UiFormStatus.COMPLETE;
            }
            return "";
          } else {
            return "bindPaypalEmail";
          }
        case "setPassword" /* SET_PASSWORD */:
          return "";
        case "changePassword" /* CHANGE_PASSWORD */:
          return "changePassword";
        default:
          return "";
      }
    });
  }
  /**
   * 验证码检验
   */
  checkVerifyCode(checkAccount = true) {
    return __async(this, null, function* () {
      this.formController.status = UiFormStatus.LOADING;
      yield verifyCodeApi.checkCode({
        email: this.formController.email,
        verifyCode: this.formController.verifyCode,
        checkAccount
      });
    });
  }
  /**
   * 绑定邮箱
   */
  bindEmail() {
    return __async(this, null, function* () {
      this.formController.status = UiFormStatus.LOADING;
      yield accountManagementApi.bindEmail({
        email: this.formController.email,
        verifyCode: this.formController.verifyCode
      });
      localStorage.removeItem("token");
      Message.success(`${i18n.t("account.email.change.success")}`);
    });
  }
  /**
   * 绑定PayPal邮箱
   */
  bindPaypalEmail() {
    return __async(this, null, function* () {
      this.formController.status = UiFormStatus.LOADING;
      yield accountManagementApi.bindPayPalEmail({
        email: this.formController.email,
        verifyCode: this.formController.verifyCode
      });
      this.accountInfo.paypalEmail = this.formController.email;
      Message.success(`${i18n.t("account.pay_pal.success")}`);
    });
  }
  /**
   * 修改密码
   */
  changePassword() {
    return __async(this, null, function* () {
      this.formController.status = UiFormStatus.LOADING;
      yield accountManagementApi.changePassword({
        originPassword: this.formController.originPassword,
        newPassword: this.formController.newPassword,
        repeatPassword: this.formController.repeatPassword
      });
      localStorage.removeItem("token");
      Message.success(`${i18n.t("account.password.change.success")}`);
    });
  }
  /**
   * 切换语言
   */
  switchLanguage() {
    return __async(this, null, function* () {
      this.formController.status = UiFormStatus.LOADING;
      yield accountManagementApi.switchLanguage({
        language: this.formController.language || null
      });
      if (this.formController.language) {
        localStorage.setItem("lang", this.formController.language);
        localStorage.removeItem("follow-brower-lang");
      } else {
        localStorage.removeItem("lang");
        localStorage.setItem("follow-brower-lang", "true");
      }
      this.accountInfo.language = this.formController.language;
    });
  }
}
export class EmailController extends UiFormController {
  constructor() {
    super(...arguments);
    this.email = "";
    this.verifyCode = "";
  }
}
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("account.email.email_required"),
      trigger: "blur"
    },
    {
      min: 6,
      max: 100,
      message: i18n.t("account.email.email_length"),
      trigger: "blur"
    },
    {
      type: "email",
      message: i18n.t("account.email.email_legality"),
      trigger: "blur"
    }
  ])
], EmailController.prototype, "email", 2);
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("components.verify_code.placeholder"),
      trigger: "blur"
    },
    {
      min: 6,
      max: 6,
      message: i18n.t("components.verify_code.format"),
      trigger: "blur"
    }
  ])
], EmailController.prototype, "verifyCode", 2);
export class CBController extends EmailController {
}
export class PayPalController extends EmailController {
}
export class PasswordController extends UiFormController {
  constructor() {
    super(...arguments);
    this.originPassword = "";
    this.newPassword = "";
    this.repeatPassword = "";
  }
}
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("account.password.change.original_password_placeholder"),
      trigger: "blur"
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      message: i18n.t("account.password.password_format"),
      trigger: "blur"
    },
    {
      min: 8,
      max: 16,
      message: i18n.t("account.password.password_length"),
      trigger: "blur"
    }
  ])
], PasswordController.prototype, "originPassword", 2);
__decorateClass([
  UiFormAnnotations.Rule([
    {
      required: true,
      message: i18n.t("account.password.change.new_password_required"),
      trigger: "blur"
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      message: i18n.t("account.password.password_format"),
      trigger: "blur"
    },
    {
      min: 8,
      max: 16,
      message: i18n.t("account.password.password_length"),
      trigger: "blur"
    }
  ])
], PasswordController.prototype, "newPassword", 2);
export class LanguageController extends UiFormController {
  constructor() {
    super(...arguments);
    this.language = "";
  }
}
