"use strict";
import dayjs from "dayjs";
const oneDayLong = 60 * 60 * 24 * 1e3;
function nowTime(date) {
  return date.getTime();
}
function nowDay(date) {
  return date.getDay() || 7;
}
function nowMonth(date) {
  return date.getMonth();
}
function getDateZeroTime(time) {
  return new Date(new Date(time).setHours(0, 0, 0, 0)).getTime();
}
function getDateLastTime(time) {
  return new Date(new Date(time).setHours(23, 59, 59, 0)).getTime();
}
const shortcuts = [
  {
    text: "today",
    onClick(picker) {
      const end = dayjs(dayjs().format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(dayjs().format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  },
  {
    text: "yesterday",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(1, "day").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(dayjs().subtract(1, "day").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  },
  {
    text: "this_week",
    /*    onClick(picker:{ $emit: (arg0: string, arg1: Array<string|number>) => void }) {
          const current_day_obj = dayjs().subtract(1, 'day').format("DD");
          let start = null
          if(parseInt(current_day_obj)<7){
            start = dayjs().subtract(1, 'day').format('YYYY-MM-01 00:00:00')
          }else if(parseInt(current_day_obj)<14){
            start = dayjs().subtract(1, 'day').format('YYYY-MM-08 00:00:00')
          }else if(parseInt(current_day_obj)<21){
            start = dayjs().subtract(1, 'day').format('YYYY-MM-15 00:00:00')
          }else{
            start = dayjs().subtract(1, 'day').format('YYYY-MM-22 00:00:00')
          }
          const end = dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD 23:59:59');
          picker.$emit('pick', [dayjs(start).valueOf(), dayjs(end).valueOf()]);
        }*/
    onClick(picker) {
      const startDate = new Date();
      const endDate = new Date();
      const start = getDateZeroTime(nowTime(startDate) - (nowDay(startDate) - 1) * oneDayLong);
      const end = nowTime(endDate);
      picker.$emit("pick", [start, end]);
    }
  },
  {
    text: "last_seven_days",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(0, "day").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(end.subtract(7, "day").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  },
  {
    text: "last_week",
    /*    onClick(picker:{ $emit: (arg0: string, arg1: Array<string|number>) => void }) {
          const current_day_obj = dayjs().subtract(1, 'day').format("DD");
          let start = null
          let end = null
          if(parseInt(current_day_obj)<7){
            start = dayjs().subtract(1, 'month').subtract(1, 'day').format('YYYY-MM-22 00:00:00')
          }else if(parseInt(current_day_obj)<14){
            start = dayjs().subtract(1, 'day').format('YYYY-MM-01 00:00:00')
          }else if(parseInt(current_day_obj)<21){
            start = dayjs().subtract(1, 'day').format('YYYY-MM-08 00:00:00')
          }else{
            start = dayjs().subtract(1, 'day').format('YYYY-MM-14 00:00:00')
          }
          if(parseInt(current_day_obj)<7){
            end = dayjs().subtract(1, 'month').endOf("month").format('YYYY-MM-DD 23:59:59')
          }else if(parseInt(current_day_obj)<14){
            end = dayjs().subtract(1, 'day').format('YYYY-MM-07 23:59:59')
          }else if(parseInt(current_day_obj)<21){
            end = dayjs().subtract(1, 'day').format('YYYY-MM-14 23:59:59')
          }else{
            end = dayjs().subtract(1, 'day').format('YYYY-MM-21 23:59:59')
          }
          picker.$emit('pick', [dayjs(start).valueOf(), dayjs(end).valueOf()]);
        }*/
    onClick(picker) {
      const startDate = new Date();
      const endDate = new Date();
      const start = getDateZeroTime(nowTime(startDate) - (nowDay(startDate) + 6) * oneDayLong);
      const end = getDateLastTime(nowTime(endDate) + (0 - nowDay(endDate)) * oneDayLong);
      picker.$emit("pick", [start, end]);
    }
  },
  {
    text: "this_month",
    /*    onClick(picker:{ $emit: (arg0: string, arg1: Array<string|number>) => void }) {
          const start =dayjs(dayjs().subtract(1, 'day').startOf("month")).format('YYYY-MM-DD 00:00:00');
          const end = dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD 23:59:59');
    
          picker.$emit('pick', [dayjs(start).valueOf(), dayjs(end).valueOf()]);
        }*/
    onClick(picker) {
      const startDate = new Date();
      const endDate = new Date();
      const start = getDateZeroTime(startDate.setDate(1));
      const end = nowTime(endDate);
      picker.$emit("pick", [start, end]);
    }
  },
  {
    text: "last_thirty_days",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(0, "day").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(end.subtract(30, "day").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  },
  {
    text: "last_ninety_days",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(0, "day").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(end.subtract(90, "day").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  },
  {
    text: "last_month",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(1, "day").subtract(1, "month").endOf("month").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(dayjs().subtract(1, "day").subtract(1, "month").startOf("month").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
    /*    onClick(picker:{ $emit: (arg0: string, arg1: Array<string|number>) => void }) {
          const startDate = new Date()
          const endDate = new Date()
          const start = getDateZeroTime(new Date(startDate.setMonth(nowMonth(startDate) - 1)).setDate(1))
          const end = getDateLastTime(endDate.setDate(0))
          picker.$emit('pick', [start, end]);
        }*/
  },
  {
    text: "half_of_a_year",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(0, "day").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(end.subtract(6, "month").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  },
  {
    text: "one_year",
    onClick(picker) {
      const end = dayjs(dayjs().subtract(0, "day").format("YYYY-MM-DD 23:59:59"));
      const start = dayjs(end.subtract(1, "year").format("YYYY-MM-DD 00:00:00"));
      picker.$emit("pick", [start.valueOf(), end.valueOf()]);
    }
  }
];
export default shortcuts;
