"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { Debounce } from "vue-debounce-decorator";
import {
  ProxyManagementService,
  ProxyStatus
} from "@/views/proxyManagement/services/proxy-management.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import TrafficDetailDialog from "@/views/trafficManagement/views/dialog/TrafficDetailDialog.vue";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { DownloadManager } from "../services/download";
import ExportProxyFormatDialog from "@/views/proxyManagement/components/ExportProxyFormatDialog.vue";
import { Base64 } from "js-base64";
import VueQr from "vue-qr";
let ProxyManagement = class extends Vue {
  constructor() {
    super(...arguments);
    // 代理列表获取数据的controller实例
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/proxies`)
    );
    // 搜索条件
    this.search = {
      region: "",
      status: "",
      protocol: ""
      // exportIp: "",
    };
    // 代理管理service的实例
    this.proxyManagementService = new ProxyManagementService();
    // 代理状态的对象集合
    this.proxyStatus = ProxyStatus;
    // 选中的代理列表行对象数组
    this.multipleSelection = [];
    // 获取用户session信息
    this.accountInfo = AccountInfoService.getAccountInfo();
    // 是否向用户展示绑定ClonBrowser账号的提示
    this.showBindTips = false;
    this.showPrivew = false;
    this.qrlinkUrl = "";
    this.qrlinkIP = "";
    this.downloadUtil = new DownloadManager();
  }
  created() {
    return __async(this, null, function* () {
      yield this.getCountryList();
    });
  }
  // 检测导出代理按钮是否显示可用状态
  checkButtonDisable() {
    if (this.accountInfo.hasOwnProperty("cbAccount") && this.accountInfo.cbAccount !== null) {
      return "export-button";
    }
    if (!(this.accountInfo.hasOwnProperty("cbAccount") && this.accountInfo.cbAccount !== null)) {
      return "export-button is-disabled";
    }
  }
  // 更多
  handleCommand(command) {
    return __async(this, null, function* () {
      switch (command.method) {
        case "copyAccountAndPwd":
          yield this.copyAP(command.item);
          break;
        case "downloadProxyFile":
          yield this.downloadProxyFile(command.item);
          break;
      }
    });
  }
  //下载代理文件
  downloadProxyFile(item) {
    return __async(this, null, function* () {
      try {
        const FileStreamdata = yield this.proxyManagementService.getDownloadFile(
          item.id
        );
        this.downloadUtil.downloadFile(
          FileStreamdata,
          "text/plain",
          this.$t("proxy.operate.download_proxy_file_name", {
            name: item.address,
            id: item.id
          })
        );
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  copyAP(item) {
    return __async(this, null, function* () {
      var _a, _b, _c;
      if (item.auth.length > 0) {
        let arr = [];
        for (let protocolData of item.auth) {
          if (((_a = protocolData.protocolType) == null ? void 0 : _a.indexOf("/")) !== -1) {
            let proxyType1 = protocolData.protocolType.split("/")[0];
            let proxytPort1 = protocolData.protocolPort.split("/")[0];
            let proxyType2 = protocolData.protocolType.split("/")[1];
            let proxytPort2 = protocolData.protocolPort.split("/")[1];
            let content1 = `${this.$t("proxy.copy.protocol")}:${proxyType1},IP:${item.address},${this.$t("proxy.copy.port")}:${proxytPort1},${this.$t(
              "proxy.copy.username"
            )}:${protocolData.user},${this.$t("proxy.copy.password")}:${protocolData.pass}`;
            let content2 = `${this.$t("proxy.copy.protocol")}:${proxyType2},IP:${item.address},${this.$t("proxy.copy.port")}:${proxytPort2},${this.$t(
              "proxy.copy.username"
            )}:${protocolData.user},${this.$t("proxy.copy.password")}:${protocolData.pass}`;
            if (item.type === "DYNAMIC") {
              content1 = `${this.$t("proxy.copy.protocol")}:${proxyType1},IP:${item.address},${this.$t("proxy.copy.port")}:${proxytPort1},${this.$t(
                "proxy.copy.username"
              )}:${protocolData.user},${this.$t("proxy.copy.password")}:${protocolData.pass}`;
              content2 = `${this.$t("proxy.copy.protocol")}:${proxyType2},IP:${item.address},${this.$t("proxy.copy.port")}:${proxytPort2},${this.$t(
                "proxy.copy.username"
              )}:${protocolData.user},${this.$t("proxy.copy.password")}:${protocolData.pass}`;
            }
            arr.push(content1);
            arr.push(content2);
          } else {
            let content = `${this.$t("proxy.copy.protocol")}:${(_b = protocolData.protocolType) != null ? _b : "-"},IP:${item.address},${this.$t("proxy.copy.port")}:${protocolData.protocolPort},${this.$t("proxy.copy.username")}:${protocolData.user},${this.$t(
              "proxy.copy.password"
            )}:${protocolData.pass}`;
            if (item.type === "DYNAMIC") {
              content = `${this.$t("proxy.copy.protocol")}:${(_c = protocolData.protocolType) != null ? _c : "-"},IP:${item.address},${this.$t("proxy.copy.port")}:${protocolData.protocolPort},${this.$t("proxy.copy.username")}:${protocolData.user},${this.$t(
                "proxy.copy.password"
              )}:${protocolData.pass}`;
            }
            arr.push(content);
          }
        }
        let result = arr.join("\n");
        this.$clipboard(result);
        this.$message.success(this.$t("proxy.copy.success"));
        return;
      }
      this.$message.info(this.$t("proxy.copy.noting"));
    });
  }
  changeAutoRenew(item) {
    return __async(this, null, function* () {
      try {
        let arr = [];
        arr.push(item.id);
        let data = {
          "proxyIds": arr,
          "autoRenew": item.autoRenewal
        };
        yield this.proxyManagementService.changeAutoRenew(data);
      } catch (error) {
        item.autoRenewal = !item.autoRenewal;
        throwErrorApi.printError(error);
      }
    });
  }
  // 获取国家列表
  getCountryList() {
    return __async(this, null, function* () {
      try {
        yield this.proxyManagementService.getCountryList();
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  // 获取选中的代理
  handleSelectionChange(val) {
    this.multipleSelection = val;
  }
  // 获取流量
  getTraffic(deduct) {
    return this.proxyManagementService.getTraffic(deduct);
  }
  // 修改搜索参数
  turnSearch() {
    let search = (
      /*'createdTime=sort=desc;'*/
      ""
    );
    for (let i in this.search) {
      if (this.search[i]) {
        if (i === "protocol") {
          search += `${i}=like=${this.search[i]};`;
        } else {
          search += `${i}==${this.search[i]};`;
        }
      }
    }
    search = search.substring(0, search.lastIndexOf(";"));
    return search;
  }
  // 搜索条件修改时触发
  sortChange(sort) {
    return __async(this, null, function* () {
      let search = `${this.turnSearch()};`;
      if (sort.order === "descending") {
        search += `${sort.prop}=sort=desc;`;
      } else if (sort.order === "ascending") {
        search += `${sort.prop}=sort=asc;`;
      } else {
        search += ``;
      }
      search = search.substring(0, search.lastIndexOf(";"));
      this.gridController.query.current = 1;
      this.gridController.query.params = {
        search
      };
      yield this.gridController.search();
    });
  }
  getData() {
    return __async(this, null, function* () {
      this.gridController.query.current = 1;
      this.gridController.query.params = {
        search: this.turnSearch()
      };
      yield this.gridController.search();
    });
  }
  // 流量详情
  openDetailDialog(row) {
    if (row.deduct >= 5.12) {
      this._trafficDetailDialog.open(`resourceId==${row.id}`);
    }
  }
  // 前往续费
  renewProxy(row) {
    if (row.type === "DYNAMIC") {
      let ids = [row.id];
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: ids.join(","),
          type: "dynamic"
        }
      });
    } else {
      let ids = [row.id];
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: ids.join(","),
          type: "normal"
        }
      });
    }
  }
  // 批量导出
  multipleExport() {
    try {
      if (!this.multipleSelection.length) {
        this.$message.error(this.$t("proxy.multiple_selection_tip"));
        return;
      }
      this._exportProxyDialog.open(this.multipleSelection);
    } catch (error) {
      throwErrorApi.printError(error);
    }
  }
  exportRealProxys(data) {
    return __async(this, null, function* () {
      try {
        let response = yield this.proxyManagementService.exportProxys(data.data);
        this.downloadUtil.downloadFile(
          response,
          "text/plain",
          this.$t("proxy.export_proxys_file_name")
        );
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  // 批量续费
  multipleRenew() {
    if (!this.multipleSelection.length || this.multipleSelection.length > 50) {
      this.$message.error(this.$t("proxy.renew.quantity_tip"));
      return;
    }
    if (this.multipleSelection.some(
      (item) => item.period.periodUnit !== this.multipleSelection[0].period.periodUnit
    )) {
      this.$message.error(this.$t("proxy.renew.unit_tip"));
      return;
    }
    if (this.multipleSelection.some((item) => {
      return item.type === "DYNAMIC";
    }) && this.multipleSelection.some((item) => {
      return item.type === "PROXY";
    })) {
      this.$message.error(this.$t("proxy.renew.same_type_tip"));
      return;
    }
    if (this.multipleSelection.some((item) => {
      return item.type === "DYNAMIC";
    })) {
      let res = /* @__PURE__ */ new Map();
      this.multipleSelection.filter((item) => {
        if (item.centerTypeId !== null && !res.has(item.centerTypeId)) {
          res.set(item.protocol, 1);
        }
      });
      console.log(res);
      if (res.size > 1) {
        this.$message.error(this.$t("proxy.renew.same_protocol_tip"));
        return;
      }
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: this.multipleSelection.map((item) => item.id).join(","),
          type: "dynamic"
        }
      });
    } else {
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: this.multipleSelection.map((item) => item.id).join(","),
          type: "normal"
        }
      });
    }
  }
  // 订阅代理
  subscribes() {
    return __async(this, null, function* () {
      if (!this.multipleSelection.length || this.multipleSelection.length > 50) {
        this.$message.error(this.$t("proxy.renew.quantity_tip"));
        return;
      }
      try {
        yield this.proxyManagementService.subscribes(
          this.multipleSelection.map((item) => item.id)
        );
        this.$message.success(this.$t("proxy.subscribe.success"));
        this._table.clearSelection();
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  // 切换代理使用状态
  changeProxyStatus(row, operation) {
    return __async(this, null, function* () {
      try {
        yield this.proxyManagementService.changeProxyStatus(row, operation);
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  // 删除代理
  deleteProxy(row) {
    this.$confirm(
      this.$t("proxy.delete_proxy.tip_1", {
        address: row.address
      }) + `<br/>` + this.$t("proxy.delete_proxy.tip_2"),
      this.$t("proxy.delete_proxy.title"),
      {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t("proxy.delete_proxy.delete_btn"),
        cancelButtonText: this.$t("proxy.delete_proxy.cancel_btn"),
        roundButton: true,
        type: "warning"
      }
    ).then(() => __async(this, null, function* () {
      try {
        yield this.proxyManagementService.deleteProxy(row);
        yield this.gridController.search();
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    })).catch(() => {
    });
  }
  // 切换出流量包管理页面
  goToTraffics() {
    this.$router.push("/admin/traffics");
  }
  exportProxysToCB() {
    return __async(this, null, function* () {
      if (!(this.accountInfo.hasOwnProperty("cbAccount") && this.accountInfo.cbAccount !== null)) {
        this.showBindTips = !this.showBindTips;
        return;
      }
      if (!this.multipleSelection.length || this.multipleSelection.length > 50) {
        this.$message.error(this.$t("proxy.renew.quantity_tip"));
        return;
      }
      if (this.multipleSelection.some(
        (item) => item.status !== "AVAILABLE" && item.status !== "TOBE_EXPIRED" && item.status !== "UN_AVAILABLE"
      )) {
        this.$message.error(
          this.$t("proxy.export_proxy_status_wrong_tip")
        );
        return;
      }
      try {
        yield this.proxyManagementService.exportProxysToCB(
          this.multipleSelection.map((item) => item.id)
        );
        this.$message.success(this.$t("proxy.renew.export_success"));
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  //生成二维码
  transferQrCode(row) {
    var _a, _b;
    if (row.auth && row.auth.length > 0) {
      for (let protocolData of row.auth) {
        if (((_a = protocolData.protocolType) == null ? void 0 : _a.indexOf("/")) !== -1) {
          let proxyType1 = protocolData.protocolType.split("/")[0];
          let proxytPort1 = protocolData.protocolPort.split("/")[0];
          let proxyType2 = protocolData.protocolType.split("/")[1];
          let proxytPort2 = protocolData.protocolPort.split("/")[1];
          if (proxyType1 === "SOCKS") {
            let toBase64 = `${protocolData.user}:${protocolData.pass}@${row.address}:${proxytPort1}`;
            let realData = Base64.toBase64(toBase64);
            let result = `socks://${realData}?obfs=none`;
            return result;
          }
          if (proxyType2 === "SOCKS") {
            let toBase64 = `${protocolData.user}:${protocolData.pass}@${row.address}:${proxytPort2}`;
            let realData = Base64.toBase64(toBase64);
            let result = `socks://${realData}?obfs=none`;
            return result;
          }
        } else {
          if (protocolData.protocolType === "SOCKS") {
            let toBase64 = `${protocolData.user}:${protocolData.pass}@${row.address}:${protocolData.protocolPort}`;
            let realData = Base64.toBase64(toBase64);
            let result = `socks://${realData}?obfs=none`;
            return result;
          }
          if (protocolData.protocolType === "HTTP") {
            let toBase64 = `${protocolData.user}:${protocolData.pass}@${row.address}:${protocolData.protocolPort}`;
            let realData = Base64.toBase64(toBase64);
            let result = `http://${realData}?obfs=none`;
            return result;
          }
        }
      }
    } else {
      let data = row.auth;
      if (((_b = data.protocolType) == null ? void 0 : _b.indexOf("/")) !== -1) {
        let proxyType1 = data.protocolType.split("/")[0];
        let proxytPort1 = data.protocolPort.split("/")[0];
        let proxyType2 = data.protocolType.split("/")[1];
        let proxytPort2 = data.protocolPort.split("/")[1];
        if (proxyType1 === "SOCKS") {
          let toBase64 = `${data.user}:${data.pass}@${row.address}:${proxytPort1}`;
          let realData = Base64.toBase64(toBase64);
          let result = `socks://${realData}?obfs=none`;
          return result;
        }
        if (proxyType2 === "SOCKS") {
          let toBase64 = `${data.user}:${data.pass}@${row.address}:${proxytPort2}`;
          let realData = Base64.toBase64(toBase64);
          let result = `socks://${realData}?obfs=none`;
          return result;
        }
      } else {
        if (data.protocolType === "SOCKS") {
          let toBase64 = `${data.user}:${data.pass}@${row.address}:${data.protocolPort}`;
          let realData = Base64.toBase64(toBase64);
          let result = `socks://${realData}?obfs=none`;
          return result;
        }
        if (data.protocolType === "HTTP") {
          let toBase64 = `${data.user}:${data.pass}@${row.address}:${data.protocolPort}`;
          let realData = Base64.toBase64(toBase64);
          let result = `http://${realData}?obfs=none`;
          return result;
        }
      }
    }
    return "";
  }
  //预览弹窗
  showPrivewDialog(row) {
    this.qrlinkIP = row.address;
    this.qrlinkUrl = this.transferQrCode(row);
    this.showPrivew = true;
  }
};
__decorateClass([
  Ref("table")
], ProxyManagement.prototype, "_table", 2);
__decorateClass([
  Ref("trafficDetailDialog")
], ProxyManagement.prototype, "_trafficDetailDialog", 2);
__decorateClass([
  Ref("exportProxyDialog")
], ProxyManagement.prototype, "_exportProxyDialog", 2);
__decorateClass([
  Debounce(400)
], ProxyManagement.prototype, "copyAP", 1);
__decorateClass([
  Debounce(800)
], ProxyManagement.prototype, "changeAutoRenew", 1);
__decorateClass([
  Debounce(800)
], ProxyManagement.prototype, "getData", 1);
__decorateClass([
  Debounce(400)
], ProxyManagement.prototype, "exportRealProxys", 1);
__decorateClass([
  Debounce(400)
], ProxyManagement.prototype, "exportProxysToCB", 1);
ProxyManagement = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid,
      TrafficDetailDialog,
      ExportProxyFormatDialog,
      VueQr
    }
  })
], ProxyManagement);
export {
  ProxyManagement as default
};
