"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import PaymentCost from "@/views/payment/views/components/PaymentCost.vue";
import { Debounce } from "vue-debounce-decorator";
import { RenewService } from "@/views/payment/services/renew.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
let Renew = class extends Vue {
  constructor() {
    super(...arguments);
    // 续费的service
    this.renewService = new RenewService();
    // 是否存在多个续费的代理
    this.panel = false;
    this.renewType = "";
    this.product = "";
    // 升级弹窗（临时性）
    this.upgradeDialogVisible = false;
  }
  created() {
    return __async(this, null, function* () {
      try {
        this.renewService.renewConfig.proxyIds = this.$route.query.ids.split(",");
        this.renewService.renewConfig.type = this.$route.query.type;
        yield this.renewService.search();
        this.renewType = this.renewService.renewConfig.renewType;
        this.product = this.renewService.renewConfig.product;
        yield this.computePrice();
      } catch (error) {
        console.log(error);
        this.$message.error(error.response.data.errors.datas[0].message);
      }
    });
  }
  mounted() {
    this.upgradeDialogVisible = true;
  }
  changeType() {
    this.renewService.renewConfig.renewType = this.renewType;
  }
  changeProduct() {
    return __async(this, null, function* () {
      this.renewService.renewConfig.product = this.product;
      yield this.computePrice();
    });
  }
  computePrice() {
    return __async(this, null, function* () {
      try {
        yield this.renewService.computePrice();
        if (this.renewService.paymentCost.nowPrice > parseFloat(AccountInfoService.getAccountInfo().balance)) {
          this.payMentCost.showChargeTips = true;
          this.payMentCost.showButtonCanUse = true;
        } else {
          this.payMentCost.showChargeTips = false;
          this.payMentCost.showButtonCanUse = false;
        }
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  // 创建订单
  buildOrder() {
    return __async(this, null, function* () {
      try {
        if (this.renewService.paymentCost.nowPrice > parseFloat(AccountInfoService.getAccountInfo().balance)) {
        } else {
          const orderInfo = yield this.renewService.buildOrder();
          yield this.$router.push({
            path: "/admin/confirm",
            query: {
              o_no: orderInfo.orderNo
            }
          });
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.renewService.paymentCost.loading = false;
      }
    });
  }
};
__decorateClass([
  Ref("payment-cost")
], Renew.prototype, "payMentCost", 2);
__decorateClass([
  Debounce(800)
], Renew.prototype, "computePrice", 1);
Renew = __decorateClass([
  Component({
    components: {
      Layout,
      PaymentCost
    }
  })
], Renew);
export {
  Renew as default
};
