"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import PaymentOptions from "@/views/payment/views/components/PaymentOptions.vue";
import PaymentCost from "@/views/payment/views/components/PaymentCost.vue";
import { Debounce } from "vue-debounce-decorator";
import { PaymentService } from "@/views/payment/services/payment.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
let Payment = class extends Vue {
  constructor() {
    super(...arguments);
    // 支付Service
    this.paymentService = new PaymentService();
    // 升级弹窗（临时性）
    this.upgradeDialogVisible = false;
  }
  created() {
    return __async(this, null, function* () {
      yield this.paymentService.search("proxy");
      if (this.$route.query.hasOwnProperty("rid") && this.$route.query.rid !== "") {
        let rid = this.$route.query.rid;
        yield this.paymentService.recommandchangeConfig(rid);
      }
      yield this.computePrice();
    });
  }
  mounted() {
    this.upgradeDialogVisible = true;
  }
  computePrice() {
    return __async(this, null, function* () {
      try {
        yield this.paymentService.computePrice("proxy");
        if (this.paymentService.paymentCost.nowPrice > parseFloat(AccountInfoService.getAccountInfo().balance)) {
          this.payMentCost.showChargeTips = true;
          this.payMentCost.showButtonCanUse = true;
        } else {
          this.payMentCost.showChargeTips = false;
          this.payMentCost.showButtonCanUse = false;
        }
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  // 创建订单
  buildOrder() {
    return __async(this, null, function* () {
      var _a;
      try {
        if (this.paymentService.paymentCost.nowPrice > parseFloat(AccountInfoService.getAccountInfo().balance)) {
        } else {
          if (((_a = this.paymentService.paymentController.productSelected.type) == null ? void 0 : _a.frontPageType) === "DYNAMIC") {
            const orderInfo = yield this.paymentService.buildOrder(
              "DYNAMIC"
            );
            yield this.$router.push({
              path: "/admin/confirm",
              query: {
                o_no: orderInfo.orderNo
              }
            });
          } else {
            const orderInfo = yield this.paymentService.buildOrder(
              "proxy"
            );
            yield this.$router.push({
              path: "/admin/confirm",
              query: {
                o_no: orderInfo.orderNo
              }
            });
          }
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.paymentService.paymentCost.loading = false;
      }
    });
  }
};
__decorateClass([
  Ref("payment-cost")
], Payment.prototype, "payMentCost", 2);
__decorateClass([
  Debounce(800)
], Payment.prototype, "computePrice", 1);
Payment = __decorateClass([
  Component({
    components: {
      Layout,
      PaymentOptions,
      PaymentCost
    }
  })
], Payment);
export {
  Payment as default
};
