"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { proxyManagementApi } from "@/data/api/proxy-management/proxy-management.api";
import { v4 } from "uuid";
import i18n from "@/lang";
export class ProxyManagementService {
  constructor() {
    /// 国家列表
    this.countryList = [];
    /// 状态列表
    this.statusList = [
      {
        label: i18n.t("proxy.list.status.available"),
        value: "AVAILABLE"
      },
      {
        label: i18n.t("proxy.list.status.expiring"),
        value: "TOBE_EXPIRED"
      },
      {
        label: i18n.t("proxy.list.status.expired"),
        value: "EXPIRED"
      },
      /*		{
      			label: i18n.t('proxy.list.status.released'),
      			value: 'RELEASED',
      		},*/
      /*		{
      			label: i18n.t('proxy.list.status.deactivate'),
      			value: 'DEACTIVATE',
      		},*/
      {
        label: i18n.t("proxy.list.status.waiting_to_open"),
        value: "WAIT_OPEN"
      },
      {
        label: i18n.t("proxy.list.status.open_fail"),
        value: "OPEN_FAIL"
      },
      {
        label: i18n.t("proxy.list.status.renew_fail"),
        value: "RENEW_FAIL"
      }
    ];
  }
  /**
   * 获取国家列表
   */
  getCountryList() {
    return __async(this, null, function* () {
      this.countryList = yield proxyManagementApi.getCountryList();
    });
  }
  /**
   * 获取已消耗流量
   * @param deduct
   */
  getTraffic(deduct) {
    if (deduct < 5.12) {
      return "0";
    } else if (deduct < 1024 * 1024) {
      return `${parseFloat((deduct / 1024).toFixed(2))}KB`;
    } else if (deduct < 1024 * 1024 * 1024) {
      return `${parseFloat((deduct / 1024 / 1024).toFixed(2))}MB`;
    } else {
      return `${parseFloat((deduct / 1024 / 1024 / 1024).toFixed(2))}GB`;
    }
  }
  /**
   * 一键订阅
   * @param ids
   */
  subscribes(ids) {
    return __async(this, null, function* () {
      yield proxyManagementApi.subscribes({
        ids
      });
    });
  }
  /**
   * 停用 / 启用
   * @param row
   * @param operation
   */
  changeProxyStatus(row, operation) {
    return __async(this, null, function* () {
      yield proxyManagementApi.changeProxyStatus({
        ids: [row.id],
        operation
      });
      if (operation === "START") {
        if (row.surplusDay >= 0 && row.surplusDay <= 7) {
          row.status = "TOBE_EXPIRED";
        } else {
          row.status = "AVAILABLE";
        }
      } else {
        row.status = "DEACTIVATE";
      }
      v4();
    });
  }
  /**
   * 删除
   * @param row
   */
  deleteProxy(row) {
    return __async(this, null, function* () {
      yield proxyManagementApi.deleteProxy({
        ids: [row.id]
      });
    });
  }
  /**
   * 导出代理至ClonBrowser
   * @param ids 
   */
  exportProxysToCB(ids) {
    return __async(this, null, function* () {
      yield proxyManagementApi.exportCB(ids);
    });
  }
  /**
   * 下载代理文件
   * @param id 
   */
  getDownloadFile(id) {
    return __async(this, null, function* () {
      return yield proxyManagementApi.downloadFile(id);
    });
  }
  /**
   * 导出代理
   */
  exportProxys(data) {
    return __async(this, null, function* () {
      return yield proxyManagementApi.exportProxies(data);
    });
  }
  /**
   * 开关自动续费
   * @param data 
   * @returns 
   */
  changeAutoRenew(data) {
    return __async(this, null, function* () {
      return yield proxyManagementApi.changeAutoRenew(data);
    });
  }
}
export var ProxyStatus = /* @__PURE__ */ ((ProxyStatus2) => {
  ProxyStatus2["UN_AVAILABLE"] = "unavailable";
  ProxyStatus2["AVAILABLE"] = "available";
  ProxyStatus2["TOBE_EXPIRED"] = "expiring";
  ProxyStatus2["EXPIRED"] = "expired";
  ProxyStatus2["RELEASED"] = "released";
  ProxyStatus2["DEACTIVATE"] = "deactivate";
  ProxyStatus2["WAIT_OPEN"] = "waiting_to_open";
  ProxyStatus2["OPEN_FAIL"] = "open_fail";
  ProxyStatus2["RENEW_FAIL"] = "renew_fail";
  return ProxyStatus2;
})(ProxyStatus || {});
export var PeriodUnit = /* @__PURE__ */ ((PeriodUnit2) => {
  PeriodUnit2["Day"] = "\u5929";
  PeriodUnit2["Month"] = "\u6708";
  PeriodUnit2["Week"] = "\u5468";
  return PeriodUnit2;
})(PeriodUnit || {});
