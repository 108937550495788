"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, PropSync, Vue } from "vue-property-decorator";
import { TrafficController } from "@/views/payment/services/payment.service";
let TrafficOptions = class extends Vue {
  changeConfig(configName, parentId) {
    this._trafficController.changeConfig(configName, parentId);
    this.computePrice();
  }
  computePrice() {
    this.$emit("computePrice");
  }
};
__decorateClass([
  PropSync("trafficController", {
    default: function() {
      return new TrafficController();
    }
  })
], TrafficOptions.prototype, "_trafficController", 2);
TrafficOptions = __decorateClass([
  Component({
    components: {}
  })
], TrafficOptions);
export {
  TrafficOptions as default
};
