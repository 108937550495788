"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { ProductService } from "@/views/payment/services/product.service";
import _ from "lodash";
import { OrderService } from "@/shared/services/payment/order.service";
import i18n from "@/lang";
export class PaymentService {
  constructor() {
    /// 商品相关
    this.paymentController = new PaymentController();
    /// 流量包相关
    this.trafficController = new TrafficController();
    /// 应用相关
    this.vpsController = new VPSController();
    /// 订单相关
    this.orderService = new OrderService();
    /// 价格
    this.paymentCost = {
      loading: true,
      nowPrice: 0,
      originPrice: 0
    };
    this.paymentType = "proxy";
  }
  /**
   * 获取商品列表
   * @param type
   */
  search(type) {
    return __async(this, null, function* () {
      if (type === "proxy") {
        yield this.paymentController.search();
      } else if (type === "VPS") {
        yield this.vpsController.search();
      } else {
        yield this.trafficController.search();
      }
    });
  }
  /**
   * 选中推荐SKU
   */
  recommandchangeConfig(rid) {
    return __async(this, null, function* () {
      yield this.paymentController.recommandchangeConfig("product", rid);
    });
  }
  /**
   * 计算价格
   * @param type
   */
  computePrice(type) {
    return __async(this, null, function* () {
      this.paymentCost.loading = true;
      let orderPrice;
      if (type === "proxy") {
        if (this.paymentController.productSelected.type.frontPageType === "DYNAMIC") {
          orderPrice = yield this.orderService.computePrice({
            type: "DYNAMIC",
            productId: this.paymentController.productSelected.product.id,
            number: this.paymentController.productSelected.number
          });
        } else {
          orderPrice = yield this.orderService.computePrice({
            type: "newProxy",
            productId: this.paymentController.productSelected.product.id,
            number: this.paymentController.productSelected.number
          });
        }
      } else if (type === "VPS") {
        orderPrice = yield this.orderService.computePrice({
          type: "VPS",
          productId: this.vpsController.vpsSelected.product.id,
          number: this.vpsController.vpsSelected.number
        });
      } else {
        orderPrice = yield this.orderService.computePrice({
          type: "trafficPackage",
          productId: this.trafficController.trafficSelected.product.id,
          number: this.trafficController.trafficSelected.number
        });
      }
      this.paymentCost.nowPrice = orderPrice.nowPrice;
      this.paymentCost.originPrice = orderPrice.originPrice;
      this.paymentCost.loading = false;
    });
  }
  /**
   * 创建订单
   * @param type
   */
  buildOrder(type) {
    return __async(this, null, function* () {
      this.paymentCost.loading = true;
      let orderInfo;
      if (type === "proxy") {
        orderInfo = yield this.orderService.buildOrder({
          type: "newPurchase",
          productId: this.paymentController.productSelected.product.id,
          number: this.paymentController.productSelected.number,
          platform: this.paymentController.productSelected.platform
        });
      } else if (type === "DYNAMIC") {
        let data = {
          productId: this.paymentController.productSelected.product.id,
          number: this.paymentController.productSelected.number,
          platform: this.paymentController.productSelected.platform
        };
        if (this.paymentController.productSelected.region) {
          data.regionId = this.paymentController.productSelected.region;
        }
        if (this.paymentController.selectedDynamicData.length > 1) {
          for (let i = 0; i < this.paymentController.selectedDynamicData.length; i++) {
            if (i === 0 && this.paymentController.selectedDynamicData[0] !== "null") {
              data.ispId = this.paymentController.selectedDynamicData[i];
            }
            if (i === 1 && this.paymentController.selectedDynamicData[1] !== "null") {
              data.chauId = this.paymentController.selectedDynamicData[i];
            }
            if (i === 2 && this.paymentController.selectedDynamicData[2] !== "null") {
              data.cityId = this.paymentController.selectedDynamicData[i];
            }
          }
        }
        if (this.paymentController.productSelected.dynamicType) {
          data.dynamicType = this.paymentController.productSelected.dynamicType;
        }
        if (this.paymentController.productSelected.dynamicTime) {
          data.dynamicTime = this.paymentController.productSelected.dynamicTime;
        }
        orderInfo = yield this.orderService.buildDynamicOrder(data);
      } else if (type === "VPS") {
        orderInfo = yield this.orderService.buildOrder({
          type: "VPS",
          productId: this.vpsController.vpsSelected.product.id,
          number: this.vpsController.vpsSelected.number,
          platform: this.vpsController.vpsSelected.platform
        });
      } else {
        orderInfo = yield this.orderService.buildOrder({
          type: "trafficPackage",
          productId: this.trafficController.trafficSelected.product.id,
          number: this.trafficController.trafficSelected.number,
          platform: "coin"
        });
      }
      this.paymentCost.loading = false;
      return orderInfo;
    });
  }
}
export class PaymentController {
  constructor() {
    /// 商品Service
    this.productData = new ProductService();
    /// 是否加载中
    this.loading = false;
    /// 实际展示配置列表
    this.productConfig = {
      typeList: [],
      regionList: [],
      areaList: [],
      categoryList: [],
      protocolList: [],
      productList: []
    };
    ///购买的ISP配置
    this.selectedDynamicData = ["null"];
    /// 购买配置
    this.productSelected = {
      product: {},
      number: 1,
      platform: "coin"
    };
  }
  /**
   * 初始化商品列表
   */
  init() {
    return __async(this, null, function* () {
      yield this.productData.init("proxy");
    });
  }
  /**
   * 获取配置列表
   */
  search() {
    return __async(this, null, function* () {
      this.loading = true;
      yield this.init();
      yield this.changeConfig("type", "");
      this.loading = false;
    });
  }
  /**
   * 修改某个配置
   * @param configName
   * @param id
   */
  changeConfig(configName, id) {
    return __async(this, null, function* () {
      var _a;
      let _configList = /* @__PURE__ */ new Set();
      switch (configName) {
        case "type":
          _configList = this.productData.getDatas("types").values();
          this.getConfigList(configName, _configList);
          yield this.changeConfig("region", this.productSelected.type.id);
          return;
        case "region":
          if (this.productSelected.type.frontPageType === "DYNAMIC") {
            this.productSelected.region = this.productData._dynamicRegions[0].id;
            _configList = this.productData.getRelationDatas("types", id);
            this.getConfigList("product", _configList);
          } else {
            _configList = this.productData.getRelationDatas("types", id);
            this.getConfigList(configName, _configList);
            yield this.changeConfig("area", (_a = this.productSelected.region) != null ? _a : "");
          }
          return;
        case "area":
          if (this.productSelected.type.frontPageType === "DYNAMIC") {
          } else {
            if (id === "all_region") {
              this.productConfig.regionList.forEach((item) => {
                if (item.id !== "all_region") {
                  _configList = /* @__PURE__ */ new Set([
                    ..._configList,
                    ...this.productData.getRelationDatas("regions", item.id)
                  ]);
                }
              });
            } else {
              _configList = this.productData.getRelationDatas("regions", id);
            }
            this.getConfigList(configName, _configList);
            yield this.changeConfig("category", this.productSelected.area.id);
          }
          return;
        case "category":
          _configList = this.productData.getRelationDatas("areas", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("protocol", this.productSelected.category.id);
          return;
        case "protocol":
          _configList = this.productData.getRelationDatas("categorys", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("product", this.productSelected.protocol.id);
          return;
        case "product":
          if (this.productSelected.type.frontPageType === "DYNAMIC") {
          } else {
            _configList = this.productData.getRelationDatas("protocols", id);
            this.getConfigList(configName, _configList);
          }
          return;
        default:
          return;
      }
    });
  }
  /** 
   * 迭代组件处理器
   */
  checkChildern(datas) {
    for (let child of datas) {
      if (child.type === "ISP") {
        if (child.name === "ISP") {
          let data = {
            "type": "chau",
            "id": "null",
            "name": i18n.t("product.config.isp_random"),
            "childrens": null
          };
          if (child.childrens !== null) {
            let arr = [data, ...child.childrens];
            child.childrens = arr;
          } else {
            let arr = [data];
            child.childrens = arr;
          }
        } else {
          let data = {
            "type": "chau",
            "id": "null",
            "name": i18n.t("product.config.isp_random"),
            "childrens": [
              {
                "type": "city",
                "id": "null",
                "name": i18n.t("product.config.isp_random"),
                "childrens": null
              }
            ]
          };
          if (child.childrens !== null) {
            let arr = [data, ...child.childrens];
            child.childrens = arr;
          } else {
            let arr = [data];
            child.childrens = arr;
          }
        }
      }
      if (child.hasOwnProperty("childrens") && child.childrens !== null) {
        this.checkChildern(child.childrens);
      } else {
        delete child.childrens;
      }
    }
  }
  /**
   * 修改动态配置
   */
  changeDynamicConfig(regionId) {
    return __async(this, null, function* () {
      const data = yield this.productData.getRegionDatas(regionId);
      if (data.childrens !== null) {
        this.checkChildern(data.childrens);
      }
      return data.childrens;
    });
  }
  /**
   * 根据ID配置推荐SKU
   */
  recommandchangeConfig(configName, configId) {
    return __async(this, null, function* () {
      const flag = yield this.changeProductData(configName, configId);
      if (flag === false) {
        yield this.changeConfig("type", configId);
        return;
      }
      switch (configName) {
        case "product":
          return;
        case "protocol":
          yield this.changeConfig("product", configId);
          return;
        case "catrgory":
          yield this.changeConfig("protocol", configId);
          return;
        case "area":
          yield this.changeConfig("catrgory", configId);
          return;
        case "region":
          yield this.changeConfig("area", configId);
          return;
        case "type":
          yield this.changeConfig("region", configId);
          return;
      }
    });
  }
  /**
   * 根据Id整体修改配置信息
   */
  changeProductData(configName, configId) {
    return __async(this, null, function* () {
      let _configList = /* @__PURE__ */ new Set();
      switch (configName) {
        case "product":
          let product_item = this.productData.getData("products", configId);
          if (!product_item) {
            return false;
          }
          this.productSelected[configName] = product_item;
          if (product_item.parentType === "type") {
            _configList = this.productData.getRelationDatas("types", product_item.parentID);
            this.productConfig[`${configName}List`] = _.sortBy(
              Array.from(_configList),
              (item) => item.sort
            );
          } else {
            _configList = this.productData.getRelationDatas("protocols", product_item.parentID);
            this.productConfig[`${configName}List`] = _.sortBy(
              Array.from(_configList),
              (item) => item.sort
            );
          }
          if (product_item.parentType === "type") {
            yield this.changeProductData("type", product_item.parentID);
          } else {
            yield this.changeProductData("protocol", product_item.parentID);
          }
          return true;
        case "protocol":
          let protocol_item = this.productData.getData("protocols", configId);
          if (!protocol_item) {
            return false;
          }
          this.productSelected[configName] = protocol_item;
          _configList = this.productData.getRelationDatas("categorys", protocol_item.parentID);
          this.productConfig[`${configName}List`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          yield this.changeProductData("category", protocol_item.parentID);
          return true;
        case "category":
          let catrgory_item = this.productData.getData("categories", configId);
          if (!catrgory_item) {
            return false;
          }
          this.productSelected[configName] = catrgory_item;
          _configList = this.productData.getRelationDatas("areas", catrgory_item.parentID);
          this.productConfig[`${configName}List`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          yield this.changeProductData("area", catrgory_item.parentID);
          return true;
        case "area":
          let area_item = this.productData.getData("areas", configId);
          if (!area_item) {
            return false;
          }
          this.productSelected[configName] = area_item;
          _configList = this.productData.getRelationDatas("regions", area_item.parentID);
          this.productConfig[`${configName}List`] = _.sortBy(
            Array.from(_configList),
            (item) => item.sort
          );
          yield this.changeProductData("region", area_item.parentID);
          return true;
        case "region":
          if (configId === "all_region") {
            this.productSelected[configName] = "all_region";
            this.productConfig.regionList.forEach((item) => {
              if (item.id !== "all_region") {
                _configList = /* @__PURE__ */ new Set([
                  ..._configList,
                  ...this.productData.getRelationDatas("regions", item.id)
                ]);
              }
            });
            yield this.changeProductData("type", this.productSelected.type.id);
          } else {
            let region_item = this.productData.getData("regions", configId);
            if (!region_item) {
              return false;
            }
            this.productSelected[configName] = region_item.id;
            _configList = this.productData.getRelationDatas("types", region_item.parentID);
            this.productConfig[`${configName}List`] = _.sortBy(
              Array.from(_configList),
              (item) => item.sort
            );
            this.productConfig[`${configName}List`].unshift({
              id: "all_region",
              name: i18n.t("product.config.all_regions"),
              parentType: "type",
              parentID: this.productSelected.type.id
            });
            yield this.changeProductData("type", region_item.parentID);
          }
          return true;
        case "type":
          let type_item = this.productData.getData("types", configId);
          if (!type_item) {
            return false;
          }
          this.productSelected[configName] = type_item;
          if (type_item.frontPageType === "DYNAMIC") {
            let _configListMap = this.productData.getDatas("types");
            _configList = _configListMap.values();
            this.productConfig[`${configName}List`] = _.sortBy(
              Array.from(_configList),
              (item) => item.sort
            );
          } else {
            let _configListMap = this.productData.getDatas("types");
            _configList = _configListMap.values();
            this.productConfig[`${configName}List`] = _.sortBy(
              Array.from(_configList),
              (item) => item.sort
            );
          }
          return true;
      }
    });
  }
  /**
   * 获取修改配置后的配置列表
   * @param configName
   * @param _configList
   */
  getConfigList(configName, _configList) {
    for (const _configName in this.productConfig) {
      if (_configName === `${configName}List`) {
        this.productConfig[_configName] = _.sortBy(
          Array.from(_configList),
          (item) => item.sort
        );
        if (configName === "region") {
          this.productConfig[_configName].unshift({
            id: "all_region",
            name: i18n.t("product.config.all_regions"),
            parentType: "type",
            parentID: this.productSelected.type.id
          });
          let selected = false;
          for (const item of this.productConfig[_configName]) {
            if (item.marks && item.marks.tags.includes("DEFAULT_SELECTED")) {
              this.productSelected[configName] = item.id;
              selected = true;
              break;
            }
          }
          if (selected === false) {
            this.productSelected[configName] = this.productConfig[_configName][0].id;
          }
        } else {
          let selected = false;
          for (const item of this.productConfig[_configName]) {
            if (item.marks && item.marks.tags.includes("DEFAULT_SELECTED")) {
              this.productSelected[configName] = item;
              selected = true;
              break;
            }
          }
          if (selected === false) {
            this.productSelected[configName] = this.productConfig[_configName][0];
          }
        }
      }
    }
  }
}
export class TrafficController {
  constructor() {
    /// 商品Service
    this.productData = new ProductService();
    /// 是否加载中
    this.loading = false;
    /// 实际展示配置列表
    this.trafficConfig = {
      comboList: [],
      sizeList: [],
      productList: []
    };
    /// 购买配置
    this.trafficSelected = {
      product: {},
      number: 1
    };
  }
  /**
   * 初始化商品列表
   */
  init() {
    return __async(this, null, function* () {
      yield this.productData.init("trafficsPackage");
    });
  }
  /**
   * 获取配置列表
   */
  search() {
    return __async(this, null, function* () {
      this.loading = true;
      yield this.init();
      yield this.changeConfig("combo", "");
      this.loading = false;
    });
  }
  /**
   * 修改某个配置
   * @param configName
   * @param id
   */
  changeConfig(configName, id) {
    return __async(this, null, function* () {
      let _configList = /* @__PURE__ */ new Set();
      switch (configName) {
        case "combo":
          _configList = this.productData.getDatas("setMeal").values();
          this.getConfigList(configName, _configList);
          yield this.changeConfig("size", this.trafficSelected.combo.id);
          return;
        case "size":
          _configList = this.productData.getRelationDatas("setMeals", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("product", this.trafficSelected.size.id);
          return;
        case "product":
          _configList = this.productData.getRelationDatas("sizes", id);
          this.getConfigList(configName, _configList);
          return;
        default:
          return;
      }
    });
  }
  /**
   * 获取修改配置后的配置列表
   * @param configName
   * @param _configList
   */
  getConfigList(configName, _configList) {
    for (const _configName in this.trafficConfig) {
      if (_configName === `${configName}List`) {
        this.trafficConfig[_configName] = _.sortBy(
          Array.from(_configList),
          (item) => item.sort
        );
        this.trafficSelected[configName] = this.trafficConfig[_configName][0];
      }
    }
  }
}
export class VPSController {
  constructor() {
    /// 商品Service
    this.productData = new ProductService();
    /// 是否加载中
    this.loading = false;
    /// 实际展示配置列表
    this.vpsConfig = {
      typeList: [],
      regionList: [],
      areaList: [],
      categoryList: [],
      productList: []
    };
    /// 购买配置
    this.vpsSelected = {
      type: {
        id: "",
        name: "",
        parentID: "",
        parentType: "",
        sort: 0
      },
      region: {
        id: "",
        name: "",
        parentID: "",
        parentType: "",
        sort: 0
      },
      area: {
        id: "",
        name: "",
        country: "",
        icon: "",
        parentID: "",
        parentType: "",
        sort: 0
      },
      category: {
        id: "",
        name: "",
        monthPrice: 0,
        parentID: "",
        parentType: "",
        sort: 0
      },
      product: {
        id: "",
        name: "",
        parentID: "",
        parentType: "",
        sort: 0
      },
      number: 1,
      platform: "coin"
    };
  }
  /**
   * 初始化商品列表
   */
  init() {
    return __async(this, null, function* () {
      yield this.productData.init("VPS");
    });
  }
  /**
   * 获取配置列表
   */
  search() {
    return __async(this, null, function* () {
      this.loading = true;
      yield this.init();
      yield this.changeConfig("type", "");
      this.loading = false;
    });
  }
  /**
   * 修改某个配置
   * @param configName
   * @param id
   */
  changeConfig(configName, id) {
    return __async(this, null, function* () {
      var _a, _b, _c;
      let _configList = /* @__PURE__ */ new Set();
      switch (configName) {
        case "type":
          _configList = this.productData.getDatas("types").values();
          this.getConfigList(configName, _configList);
          yield this.changeConfig("region", this.vpsSelected.type.id);
          return;
        case "region":
          _configList = this.productData.getRelationDatas("types", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("area", (_a = this.vpsSelected.region.id) != null ? _a : "");
          return;
        case "area":
          _configList = this.productData.getRelationDatas("regions", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("category", (_b = this.vpsSelected.area.id) != null ? _b : "");
          return;
        case "category":
          _configList = this.productData.getRelationDatas("areas", id);
          this.getConfigList(configName, _configList);
          yield this.changeConfig("product", (_c = this.vpsSelected.category.id) != null ? _c : "");
          return;
        case "product":
          _configList = this.productData.getRelationDatas("categorys", id);
          this.getConfigList(configName, _configList);
          return;
        default:
          return;
      }
    });
  }
  /**
   * 获取修改配置后的配置列表
   * @param configName
   * @param _configList
   */
  getConfigList(configName, _configList) {
    for (const _configName in this.vpsConfig) {
      if (_configName === `${configName}List`) {
        this.vpsConfig[_configName] = _.sortBy(
          Array.from(_configList),
          (item) => item.sort
        );
        this.vpsSelected[configName] = this.vpsConfig[_configName][0];
      }
    }
  }
}
