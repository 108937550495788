"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Ref, Vue, PropSync } from "vue-property-decorator";
import UiForm, { UiFormController } from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import { LoginService } from "@/views/auth/services/login.service";
import { Debounce } from "vue-debounce-decorator";
import { AuthService } from "@/views/auth/services/auth.service";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
  }
}
let Login = class extends Vue {
  constructor() {
    super(...arguments);
    // 登录表单controller
    this.controller = new FormController();
    // 登录service
    this.loginService = new LoginService();
    // 主体请求serive
    this.authService = new AuthService();
    // 登录账号校验规则
    this.loginAccountRules = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.email_required"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 100,
        message: this.$root.$i18n.t("auth.info.email_length"),
        trigger: "blur"
      },
      {
        type: "email",
        message: this.$root.$i18n.t("auth.info.email_legality"),
        trigger: "blur"
      }
    ];
    // 登录密码校验规则
    this.loginPasswordRules = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: this.$root.$i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: this.$root.$i18n.t("auth.info.password_length"),
        trigger: "blur"
      }
    ];
  }
  mounted() {
    const data = this.authService.checkRemember();
    this.receiveRememberPw(data);
  }
  // 拿到保存的账号密码填入表单
  receiveRememberPw(data) {
    if (data !== false) {
      this.controller.account = data.account;
      this.controller.password = data.password;
    }
  }
  // 修改记住密码参数
  changeRememberPw(value) {
    this.loginService.changeRememberPw(value);
  }
  // 重置表单控制器 弃用
  resetController() {
    this.controller = new FormController();
  }
  submit() {
    this.$emit("login", this.controller);
  }
  // 前往注册页
  goToRegister() {
    this._form._form.resetFields();
    this.$emit("goToRegister");
  }
  // 前往忘记密码页
  goToForgetPassword() {
    this._form._form.resetFields();
    this.$emit("goToForgetPassword");
  }
};
__decorateClass([
  Ref("loginForm")
], Login.prototype, "_form", 2);
__decorateClass([
  PropSync(
    "rememberPw",
    { type: Boolean, default: false }
  )
], Login.prototype, "_rememberPw", 2);
__decorateClass([
  Debounce(400)
], Login.prototype, "submit", 1);
Login = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputPassword
    }
  })
], Login);
export {
  Login as default
};
