"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import MainPage from "@/views/main/views/mainPage.vue";
import Contacts from "@/views/main/views/Contacts.vue";
import FAQ from "@/views/main/views/FAQ.vue";
import IPv4Page from "@/views/main/views/ipv4Page.vue";
import IPv6Page from "@/views/main/views/ipv6Page.vue";
import SharedIpv4 from "@/views/main/views/sharedIpv4.vue";
const earth = require("@/assets/images/index/Eng.png");
const ico = require("@/assets/logo.png");
const icon = require("@/assets/images/index/bottom-logo.png");
let LayoutMain = class extends Vue {
  constructor() {
    super(...arguments);
    this.logo = ico;
    this.lang = earth;
    this.icon = icon;
    this.layout_active = "home";
    this.nowLang = "";
  }
  selectMenu(index) {
    this.layout_active = index;
  }
  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang) {
      this.nowLang = lang;
    } else {
      localStorage.setItem("lang", "en-US");
      this.nowLang = "en-US";
    }
    if (this.$route.query && this.$route.query.hasOwnProperty("t")) {
      let active = this.$route.query.t;
      this.layout_active = active;
    }
  }
  toLogin() {
    this.$router.push("/auth");
  }
  changeLang() {
    var _a;
    localStorage.setItem("lang", this.nowLang);
    localStorage.removeItem("follow-brower-lang");
    this.$root.$i18n.locale = this.nowLang;
    document.title = this.$t(`route.${(_a = this.$route.meta) == null ? void 0 : _a.title}`);
  }
};
LayoutMain = __decorateClass([
  Component({
    components: {
      MainPage,
      Contacts,
      FAQ,
      IPv4Page,
      IPv6Page,
      SharedIpv4
    }
  })
], LayoutMain);
export {
  LayoutMain as default
};
