import { render, staticRenderFns } from "./AppPayment.vue?vue&type=template&id=0abe7533&scoped=true"
import script from "./AppPayment.vue?vue&type=script&lang=ts"
export * from "./AppPayment.vue?vue&type=script&lang=ts"
import style0 from "./AppPayment.vue?vue&type=style&index=0&id=0abe7533&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abe7533",
  null
  
)

export default component.exports