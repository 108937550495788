"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { accountBindAPI } from "@/shared/api/account-bind/account-bind.api";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
export class AccountBindService {
  /**
  * 检测当前CB密钥是否存在已绑定的帐号，若存在，则直接进入登录模块，跳转购买页面
  * @param key
  */
  checkHasBindAccount(key) {
    return __async(this, null, function* () {
      var _a, _b;
      try {
        const dto = {
          "CB_Key": key,
          "isRegist": false
        };
        const checkBindResponse = yield accountBindAPI.checkAccountBindStatus(dto);
        localStorage.setItem("token", (_a = checkBindResponse.token) != null ? _a : "");
        return true;
      } catch (error) {
        if (error.response && ((_b = error.response) == null ? void 0 : _b.status) === 404) {
          return false;
        } else {
          throwErrorApi.throwError(error);
        }
      }
    });
  }
  /**
   * 新用户绑定账号
   */
  bindNewAccount(key) {
    return __async(this, null, function* () {
      var _a;
      try {
        const dto = {
          "CB_Key": key,
          "isRegist": true
        };
        const bindResponse = yield accountBindAPI.createAndBindNewAccount(dto);
        localStorage.setItem("token", (_a = bindResponse.token) != null ? _a : "");
        return true;
      } catch (error) {
        throwErrorApi.throwError(error);
        return false;
      }
    });
  }
  /**
  * 若当前存在token则清除
  */
  removeToken() {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }
  /**
   * 绑定已有账号
   */
  bindAccount(key, token) {
    return __async(this, null, function* () {
      try {
        const dto = {
          "CB_Key": key,
          "token": token
        };
        yield accountBindAPI.bindAccount(dto);
        return true;
      } catch (error) {
        throwErrorApi.throwError(error);
        this.removeToken();
        return false;
      }
    });
  }
  /**
   * 验证账号密码并返回token
   */
  validAccount(email, password) {
    return __async(this, null, function* () {
      var _a;
      try {
        const dto = {
          "account": email,
          "password": password
        };
        const response = yield accountBindAPI.validAccount(dto);
        localStorage.setItem("token", (_a = response.token) != null ? _a : "");
        return response.token;
      } catch (error) {
        throwErrorApi.throwError(error);
        return false;
      }
    });
  }
}
