"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import { AccountBindService } from "@/views/accountBinding/services/account-bind.service";
import UiForm from "@/components/form/UiForm.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import {
  UiFormController
} from "@/components/form/UiForm.vue";
import i18n from "@/lang";
class ValidAccountFormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
  }
}
let AccountBinding = class extends Vue {
  constructor() {
    super(...arguments);
    // Service层对象引用
    this.accountBindService = new AccountBindService();
    // UIForm表单传值controller实例
    this.formController = new ValidAccountFormController();
    // 展示页面第一页与第二页切换控制参数
    this.showStep = "first";
    // query传值进来的CBkey存储参数
    this.CBBindKey = "";
    // 用户选择的是新用户还是已有账号
    this.userChoose = "new";
    // el-loading对象实例
    this.checkLoading = null;
    // account账号验证规则
    this.accountRules = [
      {
        required: true,
        message: i18n.t("auth.info.email_required"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 100,
        message: i18n.t("auth.info.email_length"),
        trigger: "blur"
      },
      {
        type: "email",
        message: i18n.t("auth.info.email_legality"),
        trigger: "blur"
      }
    ];
    // 密码校验规则
    this.passwordRules = [
      {
        required: true,
        message: i18n.t("auth.info.password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: i18n.t("auth.info.password_length"),
        trigger: "blur"
      }
    ];
  }
  created() {
    return __async(this, null, function* () {
    });
  }
  mounted() {
    return __async(this, null, function* () {
      this.checkLoading = this.$loading({
        lock: true,
        text: this.$i18n.t("account_binding.checking_account_loading_tips"),
        spinner: "bind-account-loading-icon",
        background: "rgba(255,255,255,1)"
      });
      if (this.$route.query.hasOwnProperty("key")) {
        this.CBBindKey = this.$route.query.key;
        this.clearAuthToken();
        yield this.checkHasBindAccount();
        this.checkLoading.close();
      }
      if (!this.$route.query.hasOwnProperty("key")) {
        this.checkLoading.close();
        this.$router.push("/auth");
      }
    });
  }
  // 每次进入此页面，若有旧token，则清除
  clearAuthToken() {
    this.accountBindService.removeToken();
  }
  // 检测是否存在已绑定的账号，不存在则留在当前页面，存在则自动登录
  checkHasBindAccount() {
    return __async(this, null, function* () {
      var _a;
      const bindAccount = yield this.accountBindService.checkHasBindAccount(
        this.CBBindKey
      );
      if (bindAccount) {
        (_a = this.checkLoading) == null ? void 0 : _a.close();
        this.$router.push("/admin/payment");
      }
    });
  }
  // 下一步按钮
  toBindAccount() {
    return __async(this, null, function* () {
      if (this.userChoose === "new") {
        yield this.toNewAccount();
        return;
      }
      if (this.userChoose === "hasAccount") {
        this.showStep = "second";
        return;
      }
    });
  }
  // 验证账号密码，正确则登录并绑定，错误则返回报错
  checkAccount() {
    return __async(this, null, function* () {
      try {
        yield this._form._form.validate();
        const token = yield this.accountBindService.validAccount(this.formController.account, this.formController.password);
        if (token) {
          yield this.hasAccount(token);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
  // 返回上一步
  goBack() {
    this.showStep = "first";
  }
  // 跳过本次绑定
  skipBind() {
    return __async(this, null, function* () {
      this.$router.push("/auth");
    });
  }
  // 未绑定且暂无账号，创建新账号且自动登录
  toNewAccount() {
    return __async(this, null, function* () {
      const result = yield this.accountBindService.bindNewAccount(this.CBBindKey);
      if (result) {
        this.$router.push("/admin/payment");
      }
    });
  }
  // 验证账号密码登录后调用，绑定账号
  hasAccount(token) {
    return __async(this, null, function* () {
      const result = yield this.accountBindService.bindAccount(this.CBBindKey, token);
      if (result) {
        this.$router.push("/admin/payment");
      }
    });
  }
};
__decorateClass([
  Ref("validAccountForm")
], AccountBinding.prototype, "_form", 2);
AccountBinding = __decorateClass([
  Component({
    components: {
      UiForm,
      InputPassword
    }
  })
], AccountBinding);
export {
  AccountBinding as default
};
