"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { UiFormStatus } from "@/components/form/UiForm.vue";
import { Base64 } from "js-base64";
import { authAPI } from "@/data/api/auth/auth.api";
import { Message } from "element-ui";
import i18n from "@/lang";
export class AuthService {
  constructor() {
    /// 表单类型
    this.authType = "login";
    /// 是否记住密码
    this.rememberPw = localStorage.getItem("_remember_pw") === "true";
  }
  /**
   * 判断是否记住密码，是则自动带入
   */
  checkRemember() {
    if (localStorage.getItem("_remember_pw") === "true") {
      return {
        "account": Base64.decode(this.getCookie("_a_e")),
        "password": Base64.decode(this.getCookie("_a_pw"))
      };
    }
    return false;
  }
  /**
   * 获取加密过的账密
   * @param key
   */
  getCookie(key) {
    if (document.cookie.length > 0) {
      let start = document.cookie.indexOf(key + "=");
      if (start !== -1) {
        start = start + key.length + 1;
        let end = document.cookie.indexOf(";", start);
        if (end === -1) {
          end = document.cookie.length;
        }
        return unescape(document.cookie.substring(start, end));
      }
    }
    return "";
  }
  /**
   * 记住密码时，将加密过的账密存储在cookie中
   * @param key
   * @param value
   */
  setCookie(key, value, age) {
    if (age) {
      var d = new Date();
      d.setTime(d.getTime() + age * 24 * 60 * 60 * 1e3);
      let expires = "expires=" + d.toUTCString();
      document.cookie = `${key}=${decodeURIComponent(Base64.encode(value))};${expires}`;
    } else {
      document.cookie = `${key}=${decodeURIComponent(Base64.encode(value))}`;
    }
  }
  /**
   * 去登录
   */
  goToLogin() {
    this.authType = "login";
  }
  /**
   * 去注册
   */
  goToRegister() {
    this.authType = "register";
  }
  /**
   * 忘记密码
   */
  goToForgetPassword() {
    this.authType = "forgetPassword";
  }
  /**
   * 注册
   */
  register(formController) {
    return __async(this, null, function* () {
      formController.status = UiFormStatus.LOADING;
      const url = window.location.href;
      const queryParam = location.search.substr(1);
      const data = {
        account: formController.account,
        password: formController.password,
        verifyCode: formController.verifyCode
      };
      if (queryParam) {
        data.queryParam = queryParam;
      }
      let cid = localStorage.getItem("_cid");
      if (cid && cid !== "") {
        data.clientId = cid;
      }
      yield authAPI.register(data);
      if (cid && cid !== "") {
        localStorage.removeItem("_cid");
      }
      Message.success(i18n.t("components.register_success"));
      return true;
    });
  }
  /**
   * 找回密码
   */
  resetPassword(formController) {
    return __async(this, null, function* () {
      formController.status = UiFormStatus.LOADING;
      const data = {
        account: formController.account,
        verifyCode: formController.verifyCode,
        newPassword: formController.password,
        repeatPassword: formController.repeatPassword
      };
      yield authAPI.resetPassword(data);
      Message.success(i18n.t("components.reset_password_success"));
      this.goToLogin();
    });
  }
  /**
   * 登录
   */
  login(formController) {
    return __async(this, null, function* () {
      var _a;
      const loginVO = {
        account: formController.account,
        password: formController.password
      };
      const loginResponse = yield authAPI.login(loginVO);
      localStorage.setItem("token", (_a = loginResponse.token) != null ? _a : "");
      if (this.rememberPw) {
        localStorage.setItem("_remember_pw", "true");
        this.setCookie("_a_e", formController.account, 3);
        this.setCookie("_a_pw", formController.password, 3);
      } else {
        this.setCookie("_a_e", "");
        this.setCookie("_a_pw", "");
      }
    });
  }
}
