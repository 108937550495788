"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, PropSync, Vue } from "vue-property-decorator";
import { VPSController } from "@/views/payment/services/payment.service";
let AppPaymentOptions = class extends Vue {
  constructor() {
    super(...arguments);
    this.cascaderProps = {
      expandTrigger: "hover",
      multiple: false,
      emitPath: true,
      value: "id",
      label: "name",
      children: "childrens"
    };
  }
  // 修改选中项
  changeConfig(configName, parentId) {
    return __async(this, null, function* () {
      this._paymentController.changeConfig(configName, parentId);
      this.computePrice();
    });
  }
  //判断是否可序列化
  isJSON(str) {
    if (typeof str === "string") {
      try {
        let data = JSON.parse(str);
        return data;
      } catch (e) {
        return false;
      }
    } else {
      return str;
    }
  }
  //默认值迭代器
  findDefaultId(data, arr) {
    arr.push(data.id);
    if (data.hasOwnProperty("childrens")) {
      this.findDefaultId(data.childrens[0], arr);
    }
  }
  // 计算价格
  computePrice() {
    this.$emit("computePrice");
  }
};
__decorateClass([
  PropSync("paymentController", {
    default: function() {
      return new VPSController();
    }
  })
], AppPaymentOptions.prototype, "_paymentController", 2);
AppPaymentOptions = __decorateClass([
  Component({
    components: {}
  })
], AppPaymentOptions);
export {
  AppPaymentOptions as default
};
