"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import elementEsLocale from "element-ui/lib/locale/lang/es";
import elementTwLocale from "element-ui/lib/locale/lang/zh-TW";
import elementRuLocale from "element-ui/lib/locale/lang/ru-RU";
import elementPtLocale from "element-ui/lib/locale/lang/pt";
import elementViLocale from "element-ui/lib/locale/lang/vi";
import elementThLocale from "element-ui/lib/locale/lang/th";
const enLocale = require("./en.json");
const zhLocale = require("./zh.json");
const esLocale = require("./es.json");
const twLocale = require("./zh-tw.json");
const ruLocale = require("./ru.json");
const ptLocale = require("./pt.json");
const viLocale = require("./vi.json");
const thLocale = require("./th.json");
Vue.use(VueI18n);
const messages = {
  "en-US": __spreadValues(__spreadValues({}, enLocale), elementEnLocale),
  "zh-CN": __spreadValues(__spreadValues({}, zhLocale), elementZhLocale),
  "es-ES": __spreadValues(__spreadValues({}, esLocale), elementEsLocale),
  "zh-TW": __spreadValues(__spreadValues({}, twLocale), elementTwLocale),
  "ru-RU": __spreadValues(__spreadValues({}, ruLocale), elementRuLocale),
  "pt-PT": __spreadValues(__spreadValues({}, ptLocale), elementPtLocale),
  "vi-VN": __spreadValues(__spreadValues({}, viLocale), elementViLocale),
  "th-TH": __spreadValues(__spreadValues({}, thLocale), elementThLocale)
};
export const getBrowser = () => {
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale;
      let lang = "en-US";
      if (locale === "zh-CN") {
        lang = "zh-CN";
      }
      if (locale === "en-US") {
        lang = "en-US";
      }
      if (locale === "es-ES") {
        lang = "es-ES";
      }
      if (locale === "zh-TW") {
        lang = "zh-TW";
      }
      if (locale === "ru-RU") {
        lang = "ru-RU";
      }
      if (locale === "pt-PT") {
        lang = "pt-PT";
      }
      if (locale === "vi-VN") {
        lang = "vi-VN";
      }
      if (locale === "th-TH") {
        lang = "th-TH";
      }
      localStorage.setItem("lang", lang);
      return locale;
    }
  }
  localStorage.setItem("lang", "en-US");
  return "en-US";
};
export const getLocale = () => {
  const cookieLanguage = localStorage.getItem("lang");
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage;
    return cookieLanguage;
  }
  const isFollowBrowser = localStorage.getItem("follow-brower-lang");
  if (isFollowBrowser) {
    return getBrowser();
  }
  localStorage.setItem("lang", "en-US");
  return "en-US";
};
const i18n = new VueI18n({
  locale: getLocale(),
  // 语言标识
  fallbackLocale: "en-US",
  //默认语言
  silentFallbackWarn: true,
  //抑制警告
  messages
});
export default i18n;
