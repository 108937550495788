"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class ProxyManagementApi {
  /**
   * 获取国家列表
   */
  getCountryList() {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/proxy-regions`
      );
      return response.data.datas;
    });
  }
  /**
   * 一键订阅
   * @param vo
   */
  subscribes(vo) {
    return __async(this, null, function* () {
      return yield API.post(
        `api://base.auth/v1/subscribes`,
        vo
      );
    });
  }
  /**
   * 获取续费列表
   * @param ids
   */
  getRenewList(ids) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/products-renew`,
        {
          params: {
            proxyIds: ids.join(",")
          }
        }
      );
      return response.data;
    });
  }
  /**
   * 获取动态续费列表
   * @param ids
   */
  getDynamicRenewList(ids) {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base.auth/v1/products-renew-dynamic`, {
        params: {
          proxyIds: ids.join(",")
        }
      });
      return response.data;
    });
  }
  /**
   * 停用 / 启用
   * @param vo
   */
  changeProxyStatus(vo) {
    return __async(this, null, function* () {
      return yield API.put(
        "api://base.auth/v1/proxies-status",
        vo
      );
    });
  }
  /**
   * 删除
   * @param vo
   */
  deleteProxy(vo) {
    return __async(this, null, function* () {
      return yield API.delete(
        "api://base.auth/v1/proxies",
        {
          data: vo
        }
      );
    });
  }
  /**
   * 导出代理列表到CB
   * @param ids
   */
  exportCB(ids) {
    return __async(this, null, function* () {
      yield API.post(`api://base.auth/v1/proxies/export-cb`, {
        ids
      });
    });
  }
  /**
   * 下载代理文件信息文件流
   * @param id 
   * @returns stream
   */
  downloadFile(id) {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base.auth.file/v1/proxies/${id}/download`);
      return response.data;
    });
  }
  /**
   * 导出代理信息文件
   */
  exportProxies(data) {
    return __async(this, null, function* () {
      const response = yield API.post(`api://base.auth.file/v1/proxies/export`, data);
      return response.data;
    });
  }
  changeAutoRenew(data) {
    return __async(this, null, function* () {
      const response = yield API.put(`api://base.auth/v1/proxy-auto-renew`, data);
    });
  }
}
export const proxyManagementApi = new ProxyManagementApi();
