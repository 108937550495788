"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { CoreAccessRule } from "@/framework/access-control-lists";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import i18n, { getBrowser, getLocale } from "@/lang";
export class GuestAccess extends CoreAccessRule {
  /**
   * 路由守卫
   * @param to
   * @param from
   * @param next
   */
  next(to, from, next) {
    return __async(this, null, function* () {
      var _a;
      console.dir("============= GuestAccess ==============");
      i18n.locale = getLocale();
      if ((_a = to.meta) == null ? void 0 : _a.title) {
        document.title = i18n.t(`route.${to.meta.title}`);
      }
      next();
    });
  }
}
export class GhostAccess extends CoreAccessRule {
  /**
   * 路由守卫
   * @param to
   * @param from
   * @param next
   */
  next(to, from, next) {
    return __async(this, null, function* () {
      var _a;
      console.dir("============= GhostAccess ==============");
      const _token = localStorage.getItem("token");
      if (!_token) {
        next("/payments");
      }
      try {
        const accountInfo = yield accountInfoAPI.getAccountInfo();
        AccountInfoService.setAccountInfo(accountInfo);
        i18n.locale = getLocale();
        if ((_a = to.meta) == null ? void 0 : _a.title) {
          document.title = i18n.t(`route.${to.meta.title}`);
        }
        if (!accountInfo.temporarily) {
          next("/");
        }
        next();
      } catch (error) {
        throwErrorApi.tokenError(error, next);
      }
    });
  }
}
export class AuthAccess extends CoreAccessRule {
  /**
   * 路由守卫
   * @param to
   * @param from
   * @param next
   */
  next(to, from, next) {
    return __async(this, null, function* () {
      var _a, _b;
      console.dir("============= AuthAccess ==============");
      const _token = localStorage.getItem("token");
      if (!_token) {
        next("/auth");
      }
      try {
        const accountInfo = yield accountInfoAPI.getAccountInfo();
        AccountInfoService.setAccountInfo(accountInfo);
        if (accountInfo.temporarily && accountInfo.temporarily === true) {
          i18n.locale = getLocale();
          if ((_a = to.meta) == null ? void 0 : _a.title) {
            document.title = i18n.t(`route.${to.meta.title}`);
          }
          next("/payments");
        }
        if (accountInfo.language) {
          localStorage.setItem("lang", accountInfo.language);
        } else {
          localStorage.removeItem("lang");
        }
        i18n.locale = getLocale();
        if ((_b = to.meta) == null ? void 0 : _b.title) {
          document.title = i18n.t(`route.${to.meta.title}`);
        }
        next();
      } catch (error) {
        throwErrorApi.tokenError(error, next);
      }
    });
  }
}
export class BindingAccess extends CoreAccessRule {
  /**
   * 路由守卫
   * @param to
   * @param from
   * @param next
   */
  next(to, from, next) {
    return __async(this, null, function* () {
      var _a;
      try {
        console.dir("============= BindingAccess ==============");
        if (window.hasOwnProperty("chrome")) {
          if (window.chrome.hasOwnProperty("clientServer")) {
            i18n.locale = getBrowser();
            if ((_a = to.meta) == null ? void 0 : _a.title) {
              document.title = i18n.t(`route.${to.meta.title}`);
            }
            next();
          } else {
            next("/auth");
          }
        } else {
          next("/auth");
        }
      } catch (error) {
        throwErrorApi.tokenError(error, next);
      }
    });
  }
}
