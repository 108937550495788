"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { TrafficManagementService, TrafficStatus } from "@/views/trafficManagement/services/traffic-management.service";
import { Debounce } from "vue-debounce-decorator";
import TrafficDetailDialog from "@/views/trafficManagement/views/dialog/TrafficDetailDialog.vue";
let TrafficManagement = class extends Vue {
  constructor() {
    super(...arguments);
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/traffic-packages`)
    );
    this.search = {
      status: ""
    };
    this.trafficManagementService = new TrafficManagementService();
    this.trafficStatus = TrafficStatus;
  }
  turnSearch() {
    let search = (
      /*'createdTime=sort=desc;'*/
      ""
    );
    for (let i in this.search) {
      if (this.search[i]) {
        search += `${i}==${this.search[i]};`;
      }
    }
    search = search.substring(0, search.lastIndexOf(";"));
    return search;
  }
  getData() {
    return __async(this, null, function* () {
      this.gridController.query.current = 1;
      this.gridController.query.params = {
        search: this.turnSearch()
      };
      yield this.gridController.search();
    });
  }
  getTraffic(row, type) {
    if (type) {
      return this.trafficManagementService.getTraffic(row, type);
    } else {
      return this.trafficManagementService.getTraffic(row);
    }
  }
  openDetailDialog(id) {
    this._trafficDetailDialog.open(`packageId==${id}`);
  }
  goToTraffic() {
    this.$router.push("/admin/traffic");
  }
};
__decorateClass([
  Ref("trafficDetailDialog")
], TrafficManagement.prototype, "_trafficDetailDialog", 2);
__decorateClass([
  Debounce(800)
], TrafficManagement.prototype, "getData", 1);
TrafficManagement = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid,
      TrafficDetailDialog
    }
  })
], TrafficManagement);
export {
  TrafficManagement as default
};
