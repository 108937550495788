"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import { AccountInfoDialogType, EmailController } from "@/views/accountManagement/services/account-info-dialog.service";
let EmailForm = class extends Vue {
  constructor() {
    super(...arguments);
    this.accountInfoDialogType = AccountInfoDialogType;
  }
  validateEmail() {
    this.$emit("validateEmail");
  }
};
__decorateClass([
  PropSync(
    "controller",
    { type: EmailController, default: null }
  )
], EmailForm.prototype, "_controller", 2);
__decorateClass([
  Prop()
], EmailForm.prototype, "type", 2);
__decorateClass([
  Prop()
], EmailForm.prototype, "showConfirm", 2);
EmailForm = __decorateClass([
  Component({
    components: {
      InputVerifyCode
    }
  })
], EmailForm);
export {
  EmailForm as default
};
