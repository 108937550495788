"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { CostManagementService } from "@/views/costManagement/services/cost-management.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import shortcuts from "@/shared/time-config";
import { Debounce } from "vue-debounce-decorator";
import { OrderType } from "@/shared/services/payment/order.service";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import _ from "lodash";
import i18n from "@/lang";
let CostManagement = class extends Vue {
  constructor() {
    super(...arguments);
    // 充值页面Service
    this.costManagementService = new CostManagementService();
    // loading状态
    this.loading = false;
    // 订单记录controller
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/orders`)
    );
    // 搜索参数
    this.search = {
      type: ""
    };
    // 创建时间
    this.createdTime = [];
    // 选中起止时间
    this.pickerOptions = {};
    // 订单状态类型
    this.orderType = OrderType;
    // 可显示的支付方式
    this.paymentMethods = [
      "alipay",
      "FPX",
      "TOUCH_N_GO",
      "GRAB_PAY",
      "PIX",
      "PROMPT_PAY",
      "TRUE_MONEY",
      "STRIP",
      "WE_CHAT",
      "USDT",
      "ERC20_ETH",
      "BITCOIN_BTC"
    ];
    /// 订单类型列表
    this.typeList = [
      {
        label: i18n.t("order.type.recharge"),
        value: "topUp"
      },
      {
        label: i18n.t("order.type.new_purchase_proxy"),
        value: "newPurchase"
      },
      {
        label: i18n.t("order.type.renewal_proxy"),
        value: "renewal"
      },
      {
        label: i18n.t("order.type.traffic_package"),
        value: "trafficPackage"
      }
      // 后续需要增加搜索主机类型的订单直接放开
      // {
      // 	label: i18n.t('order.type.newHost'),
      // 	value: 'newHost',
      // },
    ];
  }
  created() {
    return __async(this, null, function* () {
      let _shortcuts = _.cloneDeep(shortcuts);
      _shortcuts.forEach((item) => {
        item.text = this.$t(`components.date_picker.${item.text}`);
      });
      this.pickerOptions.shortcuts = _shortcuts;
      this.gridController.query.params = {
        search: "createdTime=sort=desc"
      };
      yield this.getRechargeOption();
    });
  }
  mounted() {
    return __async(this, null, function* () {
      yield this.getPaymentMethods();
    });
  }
  // 验证支付方式是否可以显示
  checkPaymentMethodShow(method) {
    if (this.paymentMethods.includes(method)) {
      return true;
    } else {
      return false;
    }
  }
  // 获取可显示的支付方式
  getPaymentMethods() {
    return __async(this, null, function* () {
      try {
        this.paymentMethods = yield this.costManagementService.getPaymentMethods();
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  // 获取充值金额参数
  getRechargeOption() {
    return __async(this, null, function* () {
      try {
        yield this.costManagementService.getRechargeOption();
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  // 根据条件搜索订单
  turnSearch() {
    let search = "createdTime=sort=desc;";
    if (this.createdTime && this.createdTime.length) {
      this.search.createdStartTime = Math.ceil(this.createdTime[0] / 1e3);
      this.search.createdEndTime = Math.ceil(this.createdTime[1] / 1e3);
    } else {
      this.search.createdStartTime = "";
      this.search.createdEndTime = "";
    }
    for (let i in this.search) {
      if (this.search[i] || this.search[i] === null) {
        if (i == "createdStartTime") {
          search += `createdTime>=${this.search[i]};`;
        } else if (i == "createdEndTime") {
          search += `createdTime<=${this.search[i]};`;
        } else if (this.search[i]) {
          search += `${i}==${this.search[i]};`;
        }
      }
    }
    search = search.substring(0, search.lastIndexOf(";"));
    return search;
  }
  getData() {
    return __async(this, null, function* () {
      this.gridController.query.current = 1;
      this.gridController.query.params = {
        search: this.turnSearch()
      };
      yield this.gridController.search();
    });
  }
  // 修改选择项
  changeProduct() {
    this.costManagementService.changeProduct();
  }
  // 是否自定义金额
  changeCustomize() {
    this.costManagementService.changeCustomize();
  }
  // 修改自定义金额
  changeRechargeAutoAmount(value) {
    this.costManagementService.changeRechargeAutoAmount(value);
  }
  // 检测到选中了alipay 支付方式隐藏大于1000美元的支付数额,包含自定义填入的支付金额
  changePlatform(value) {
    this.costManagementService.changePlatform(value);
  }
  // 充值
  recharge() {
    return __async(this, null, function* () {
      if (this.costManagementService.rechargeOption.number) {
        try {
          this.loading = true;
          const orderInfo = yield this.costManagementService.buildOrder();
          if (this.costManagementService.rechargeOption.platform === "paypal") {
            yield this.$alert(
              this.$t("recharge.payment_reminder.pay_pal", { email: AccountInfoService.getAccountInfo().paypalEmail }),
              this.$t("recharge.payment_reminder.title"),
              {
                customClass: "recharge",
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("components.message_box.confirm"),
                roundButton: true,
                callback: (action) => {
                  this.loading = false;
                }
              }
            );
          } else if (this.costManagementService.rechargeOption.platform === "USDT" || this.costManagementService.rechargeOption.platform === "ERC20_ETH" || this.costManagementService.rechargeOption.platform === "BITCOIN_BTC") {
            let platform = "";
            if (this.costManagementService.rechargeOption.platform === "USDT") {
              platform = "USDT";
              this.$router.push({ "name": "payOrder", "query": { "p_a": orderInfo.pay.url, "p_t": this.costManagementService.rechargeOption.platform, "p_op": orderInfo.cryptoExchangeRateResponse.orderUsdPrice, "p_rp": orderInfo.cryptoExchangeRateResponse.shouldPayPrice, "p_pt": orderInfo.orderNo, "r_pt": platform } });
            }
            if (this.costManagementService.rechargeOption.platform === "ERC20_ETH") {
              platform = "ETH(ERC20)";
              this.$router.push({ "name": "payOrder", "query": { "p_a": orderInfo.pay.url, "p_t": this.costManagementService.rechargeOption.platform, "p_op": orderInfo.cryptoExchangeRateResponse.orderUsdPrice, "p_rp": orderInfo.cryptoExchangeRateResponse.shouldPayPrice, "p_pt": orderInfo.orderNo, "r_pt": platform } });
            }
            if (this.costManagementService.rechargeOption.platform === "BITCOIN_BTC") {
              platform = "BTC(BITCOIN)";
              this.$router.push({ "name": "payOrder", "query": { "p_a": orderInfo.pay.url, "p_t": this.costManagementService.rechargeOption.platform, "p_op": orderInfo.cryptoExchangeRateResponse.orderUsdPrice, "p_rp": orderInfo.cryptoExchangeRateResponse.shouldPayPrice, "p_pt": orderInfo.orderNo, "r_pt": platform, "p_sw": orderInfo.walletAddressSegWit } });
            }
          } else {
            yield this.$alert(
              this.$t("recharge.payment_reminder.alipay"),
              this.$t("recharge.payment_reminder.title"),
              {
                customClass: "recharge",
                confirmButtonText: this.$t("components.message_box.confirm"),
                roundButton: true,
                callback: (action) => {
                  window.open(orderInfo.pay.url, "_blank");
                  this.loading = false;
                }
              }
            );
          }
        } catch (error) {
          throwErrorApi.throwError(error);
        } finally {
          this.loading = false;
        }
      } else {
        this.$message.error(this.$t("recharge.coin.required"));
      }
    });
  }
};
__decorateClass([
  Debounce(800)
], CostManagement.prototype, "getData", 1);
CostManagement = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid
    }
  })
], CostManagement);
export {
  CostManagement as default
};
