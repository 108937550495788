"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class AccountBindAPI {
  // 检测账号是否存在绑定状态
  checkAccountBindStatus(dto) {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base/v1/api-key/token`, {
        params: {
          "isRegist": dto.isRegist
        },
        headers: {
          "X-API-KEY": dto.CB_Key
        }
      });
      return response.data;
    });
  }
  // 绑定并创建新用户
  createAndBindNewAccount(dto) {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base/v1/api-key/token`, {
        params: {
          "isRegist": dto.isRegist
        },
        headers: {
          "X-API-KEY": dto.CB_Key
        }
      });
      return response.data;
    });
  }
  // 绑定已有用户
  bindAccount(dto) {
    return __async(this, null, function* () {
      yield API.put(`api://base/v1/cb-email`, {}, {
        headers: {
          "X-API-KEY": dto.CB_Key,
          "Authorization": dto.token
        }
      });
    });
  }
  //验证账号密码
  validAccount(dto) {
    return __async(this, null, function* () {
      const response = yield API.post(`api://base/v1/login`, dto);
      return response.data;
    });
  }
}
export const accountBindAPI = new AccountBindAPI();
