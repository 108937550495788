"use strict";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import i18n from "@/lang";
export class AccountManagementService {
  constructor() {
    /// 账号信息
    this.accountInfo = AccountInfoService.getAccountInfo();
  }
  /**
   * 当前语言
   */
  getLanguage() {
    switch (this.accountInfo.language) {
      case "zh-CN":
        return "\u7B80\u4F53\u4E2D\u6587";
      case "en-US":
        return "English";
      case "es-ES":
        return "Espa\xF1ol";
      case "zh-TW":
        return "\u7E41\u9AD4\u4E2D\u6587";
      case "ru-RU":
        return "\u0420\u0443\u0441\u0441\u043A\u0438\u0439";
      case "pt-PT":
        return "Portugu\xEAs";
      case "vi-VN":
        return "Ti\u1EBFng Vi\u1EC7t";
      default:
        return i18n.t("account.language.switch.default");
    }
  }
}
