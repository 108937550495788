"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { OrderService } from "@/shared/services/payment/order.service";
import { Message } from "element-ui";
import i18n from "@/lang";
export class PaymentConfirmService {
  constructor() {
    /// 订单相关
    this.orderService = new OrderService();
    /// 订单详情
    this.orderDetail = {};
    /// 订单赠品
    this.orderGifts = /* @__PURE__ */ new Map();
  }
  /**
   * 获取订单详情
   * @param orderId
   */
  getOrderDetail(orderId) {
    return __async(this, null, function* () {
      var _a;
      this.orderDetail = yield this.orderService.getOrderDetail(orderId);
      this.orderGifts = new Map((_a = this.orderDetail.gifts) == null ? void 0 : _a.map((item) => [item.code, item]));
    });
  }
  /**
   * 完成订单
   * @param orderId
   */
  completeOrder(orderId) {
    return __async(this, null, function* () {
      yield this.orderService.completeOrder(orderId);
      Message.success(i18n.t("order.confirm.payment_successful"));
    });
  }
  /**
   * 取消订单
   * @param orderId
   */
  cancelOrder(orderId) {
    return __async(this, null, function* () {
      yield this.orderService.cancelOrder(orderId);
      Message.info(i18n.t("order.confirm.order_has_been_canceled"));
    });
  }
}
