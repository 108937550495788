"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { Debounce } from "vue-debounce-decorator";
import {
  ProxyStatus
} from "@/views/proxyManagement/services/proxy-management.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import {
  AppManagementService
} from "@/views/appManagement/services/appManagement.service";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import VueQr from "vue-qr";
import { DownloadManager } from "@/views/proxyManagement/services/download";
let AppManagement = class extends Vue {
  constructor() {
    super(...arguments);
    //   // 流量详情的弹窗对象
    //   @Ref("trafficDetailDialog") _trafficDetailDialog!: TrafficDetailDialog;
    //   // 选择导出代理类型格式弹窗
    //   @Ref("exportProxyDialog") _exportProxyDialog!: ExportProxyFormatDialog;
    // 获取VPS列表
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/virtual-private-servers`)
    );
    // 搜索条件
    this.search = {
      region: "",
      status: "",
      network: ""
      // exportIp: "",
    };
    // 应用管理service
    this.appManagementService = new AppManagementService();
    // 代理状态的对象集合
    this.proxyStatus = ProxyStatus;
    // 选中的代理列表行对象数组
    this.multipleSelection = [];
    // 获取用户session信息
    this.accountInfo = AccountInfoService.getAccountInfo();
    this.downloadUtil = new DownloadManager();
  }
  created() {
    return __async(this, null, function* () {
      yield this.getCountryList();
    });
  }
  configFiles(row) {
    return __async(this, null, function* () {
      if (row.controls) {
        let jsonstring = row.controls;
        const jsonUrl = `data:,${jsonstring}`;
        const a = document.createElement("a");
        a.href = jsonUrl;
        a.download = `${row.regionName}_${row.areaName}_${row.dispositionName}.txt`;
        a.click();
        document.removeChild(a);
        this.$message.success(this.$t("app_management.download_success"));
      }
    });
  }
  //   // 更多
  //   async handleCommand(command: any) {
  //     switch (command.method) {
  //       case "copyAccountAndPwd":
  //         await this.copyAP(command.item);
  //         break;
  //       case "downloadProxyFile":
  //         await this.downloadProxyFile(command.item);
  //         break;
  //     }
  //   }
  //   //下载代理文件
  //   async downloadProxyFile(item: Proxy) {
  //     try {
  //       const FileStreamdata = await this.proxyManagementService.getDownloadFile(
  //         item.id
  //       );
  //       this.downloadUtil.downloadFile(
  //         FileStreamdata,
  //         "text/plain",
  //         this.$t("proxy.operate.download_proxy_file_name", {
  //           name: item.address,
  //           id: item.id,
  //         }) as string
  //       );
  //     } catch (error: any) {
  //       throwErrorApi.printError(error);
  //     }
  //   }
  // 获取国家列表
  getCountryList() {
    return __async(this, null, function* () {
      try {
        yield this.appManagementService.getCountryList();
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  // 获取选中的代理
  handleSelectionChange(val) {
    this.multipleSelection = val;
  }
  // 修改搜索参数
  turnSearch() {
    let search = (
      /*'createdTime=sort=desc;'*/
      ""
    );
    for (let i in this.search) {
      if (this.search[i]) {
        if (i === "network") {
          search += `${i}=like=${this.search[i]};`;
        } else {
          search += `${i}==${this.search[i]};`;
        }
      }
    }
    search = search.substring(0, search.lastIndexOf(";"));
    return search;
  }
  // 搜索条件修改时触发
  sortChange(sort) {
    return __async(this, null, function* () {
      let search = `${this.turnSearch()};`;
      if (sort.order === "descending") {
        search += `${sort.prop}=sort=desc;`;
      } else if (sort.order === "ascending") {
        search += `${sort.prop}=sort=asc;`;
      } else {
        search += ``;
      }
      search = search.substring(0, search.lastIndexOf(";"));
      this.gridController.query.current = 1;
      this.gridController.query.params = {
        search
      };
      yield this.gridController.search();
    });
  }
  getData() {
    return __async(this, null, function* () {
      this.gridController.query.current = 1;
      this.gridController.query.params = {
        search: this.turnSearch()
      };
      yield this.gridController.search();
    });
  }
  // 前往续费
  renewProxy(row) {
    if (row.type === "DYNAMIC") {
      let ids = [row.id];
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: ids.join(","),
          type: "dynamic"
        }
      });
    } else {
      let ids = [row.id];
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: ids.join(","),
          type: "normal"
        }
      });
    }
  }
  // 批量续费
  multipleRenew() {
    if (!this.multipleSelection.length || this.multipleSelection.length > 50) {
      this.$message.error(this.$t("proxy.renew.quantity_tip"));
      return;
    }
    if (this.multipleSelection.some(
      (item) => item.period.periodUnit !== this.multipleSelection[0].period.periodUnit
    )) {
      this.$message.error(this.$t("proxy.renew.unit_tip"));
      return;
    }
    if (this.multipleSelection.some((item) => {
      return item.type === "DYNAMIC";
    }) && this.multipleSelection.some((item) => {
      return item.type === "PROXY";
    })) {
      this.$message.error(this.$t("proxy.renew.same_type_tip"));
      return;
    }
    if (this.multipleSelection.some((item) => {
      return item.type === "DYNAMIC";
    })) {
      let res = /* @__PURE__ */ new Map();
      this.multipleSelection.filter((item) => {
        if (item.centerTypeId !== null && !res.has(item.centerTypeId)) {
          res.set(item.protocol, 1);
        }
      });
      console.log(res);
      if (res.size > 1) {
        this.$message.error(this.$t("proxy.renew.same_protocol_tip"));
        return;
      }
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: this.multipleSelection.map((item) => item.id).join(","),
          type: "dynamic"
        }
      });
    } else {
      this.$router.push({
        path: "/admin/renew",
        query: {
          ids: this.multipleSelection.map((item) => item.id).join(","),
          type: "normal"
        }
      });
    }
  }
};
__decorateClass([
  Ref("table")
], AppManagement.prototype, "_table", 2);
__decorateClass([
  Debounce(800)
], AppManagement.prototype, "getData", 1);
AppManagement = __decorateClass([
  Component({
    components: {
      UiGrid,
      VueQr
    }
  })
], AppManagement);
export {
  AppManagement as default
};
