"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { productApi } from "@/shared/api/payment/product.api";
import _ from "lodash";
export class ProductService {
  constructor() {
    /// 商品数据
    this._data = /* @__PURE__ */ new Map();
    /// 层级关系
    this._relations = /* @__PURE__ */ new Map();
    this._dynamicRegions = new Array();
  }
  /**
   * 初始化
   * @param type
   */
  init(type, other_type) {
    return __async(this, null, function* () {
      try {
        const response = yield productApi.getProduct({
          type
        });
        let res = null;
        if (other_type) {
          res = yield productApi.getProduct({
            type: other_type
          });
          if (res.types) {
            for (let onlytype of res.types) {
              onlytype.frontPageType = other_type;
            }
          }
        }
        if (response.types) {
          for (let onlytype of response.types) {
            onlytype.frontPageType = type;
          }
          if (other_type) {
            response.types.push(...res.types);
            response.products.push(...res.products);
            this._dynamicRegions = res.regions;
          }
        }
        const paymentConfigs = response;
        for (const _dataType in paymentConfigs) {
          this._data.set(_dataType, /* @__PURE__ */ new Map());
          if (_.get(paymentConfigs, _dataType)) {
            for (const _dataItem of _.get(paymentConfigs, _dataType)) {
              this._data.get(_dataType).set(_dataItem.id, _dataItem);
              if (!_dataItem.parentType) {
                continue;
              }
              const _relationsName = `${_dataItem.parentType}s`;
              if (!this._relations.has(_relationsName)) {
                this._relations.set(_relationsName, /* @__PURE__ */ new Map());
              }
              if (!this._relations.get(_relationsName).has(_dataItem.parentID)) {
                this._relations.get(_relationsName).set(_dataItem.parentID, /* @__PURE__ */ new Set());
              }
              this._relations.get(_relationsName).get(_dataItem.parentID).add({
                id: _dataItem.id,
                dataType: _dataType
              });
            }
          }
        }
      } catch (error) {
        console.dir(error.response);
      }
    });
  }
  /**
   * 获取某个配置列表
   * @param dataType
   */
  getDatas(dataType) {
    return this._data.get(dataType);
  }
  /**
   * 获取某个选中配置
   * @param dataType
   * @param id
   */
  getData(dataType, id) {
    return this._data.get(dataType).get(id);
  }
  /**
   * 获取与选中配置相关的配置列表
   * @param dataType
   * @param id
   */
  getRelationDatas(dataType, id) {
    const _that = this;
    return new Set(function* () {
      if (!_that._relations.get(dataType).has(id)) {
        return;
      }
      for (const _relationItem of _that._relations.get(dataType).get(id)) {
        yield _that.getData(_relationItem.dataType, _relationItem.id);
      }
    }());
  }
  /**
   * 获取选中动态RegionId的相关选项内容
   * @param regionId
   */
  getRegionDatas(regionId) {
    return __async(this, null, function* () {
      try {
        const response = yield productApi.getRegionDatas(regionId);
        return response;
      } catch (error) {
        console.dir(error.response);
      }
    });
  }
}
