"use strict";
import { EventEmitter } from "events";
export class GeneralPage {
  constructor() {
    this.current = 1;
    this.total = 0;
    this.size = 10;
  }
}
export class GeneralGridResult extends EventEmitter {
  constructor() {
    super(...arguments);
    this._datas = new Array();
    this._page = new GeneralPage();
  }
  //数据一页
  get datas() {
    return this._datas;
  }
  get page() {
    return this._page;
  }
  //清空结果集
  clear() {
    this._datas.splice(0, this._datas.length);
    this.emit("clear");
  }
  //添加结果集
  add(item) {
    this.emit("add.item", item);
    this._datas.push(item);
  }
}
export class GeneralSearchQuery {
  constructor() {
    this.filter = null;
    this.sort = "";
    this.current = 1;
    this.size = 10;
    this.params = null;
  }
}
export class CoreGridSource {
}
