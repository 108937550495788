"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { TrafficDetailDialogService } from "@/views/trafficManagement/services/traffic-detail-dialog.service";
let TrafficDetailDialog = class extends Vue {
  constructor() {
    super(...arguments);
    // 是否显示弹窗
    this.dialogVisible = false;
    // 流量详情列表的Controller
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/traffic-detail`)
    );
    // 流量详情的Service
    this.trafficDetailDialogService = new TrafficDetailDialogService();
  }
  // 打开弹窗
  open(search) {
    return __async(this, null, function* () {
      this.dialogVisible = true;
      let startTime = new Date();
      startTime.setTime(startTime.getTime() - 3600 * 1e3 * 24 * 30);
      let realTime = Math.trunc(startTime.getTime() / 1e3);
      let start_search = `${search};createdTime=sort=desc;startTime>${realTime};`;
      this.gridController.query.params = {
        search: start_search
      };
      yield this.gridController.search();
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/traffic-detail`)
    );
  }
  // 获取流量使用情况
  getTraffic(row) {
    return this.trafficDetailDialogService.getTraffic(row);
  }
};
TrafficDetailDialog = __decorateClass([
  Component({
    components: {
      UiGrid
    }
  })
], TrafficDetailDialog);
export {
  TrafficDetailDialog as default
};
