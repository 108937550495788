"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiForm, { UiFormController } from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import { RegisterService } from "@/views/auth/services/register.service";
import { Debounce } from "vue-debounce-decorator";
export class RegisterController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
    this.verifyCode = "";
    this.confirmPassword = "";
  }
}
let Register = class extends Vue {
  constructor() {
    super(...arguments);
    // 注册表单Controller
    this.controller = new RegisterController();
    // 注册Service
    this.registerService = new RegisterService();
    // 注册账号校验规则
    this.registerAccountRules = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.email_required"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 100,
        message: this.$root.$i18n.t("auth.info.email_length"),
        trigger: "blur"
      },
      {
        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        message: this.$root.$i18n.t("auth.info.email_legality"),
        trigger: "blur"
      }
    ];
    // 注册密码校验规则
    this.registerPasswordRules = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: this.$root.$i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: this.$root.$i18n.t("auth.info.password_length"),
        trigger: "blur"
      }
    ];
    // 注册验证码校验规则
    this.registerVerifyCodeRules = [
      {
        required: true,
        message: this.$root.$i18n.t("components.verify_code.placeholder"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 6,
        message: this.$root.$i18n.t("components.verify_code.format"),
        trigger: "blur"
      }
    ];
    // 注册二次校验密码验证规则
    this.confirmPasswordRule = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.confirm_password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: this.$root.$i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: this.$root.$i18n.t("auth.info.password_length"),
        trigger: "blur"
      },
      {
        validator: this.checkConfirmPassword,
        trigger: "blur"
      }
    ];
  }
  // 校验邮箱账号验证规则
  validateEmail() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 校验二次验证密码一致性规则
  checkConfirmPassword(rule, value, callback) {
    return this.registerService.checkConfirmPassword(value, this.controller.password, callback);
  }
  submit() {
    this.$emit("register", this.controller);
  }
  // 前往登录页面
  goToLogin() {
    this._form._form.resetFields();
    this.$emit("goToLogin");
  }
  // 跳转Telegram
  toTelegram() {
    window.open("https://t.me/iprockets01", "_blank");
  }
};
__decorateClass([
  Ref("registerForm")
], Register.prototype, "_form", 2);
__decorateClass([
  Debounce(400)
], Register.prototype, "submit", 1);
Register = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputPassword,
      InputVerifyCode
    }
  })
], Register);
export {
  Register as default
};
