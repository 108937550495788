"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiForm, { UiFormController, UiFormStatus } from "@/components/form/UiForm.vue";
import EmailForm from "@/views/accountManagement/views/form/EmailForm.vue";
import CBForm from "@/views/accountManagement/views/form/CBForm.vue";
import PayPalForm from "@/views/accountManagement/views/form/PayPalForm.vue";
import PasswordForm from "@/views/accountManagement/views/form/PasswordForm.vue";
import {
  AccountInfoDialogService,
  AccountInfoDialogType
} from "@/views/accountManagement/services/account-info-dialog.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import SwitchLanguageForm from "@/views/accountManagement/views/form/SwitchLanguageForm.vue";
let AccountInfoDialog = class extends Vue {
  constructor() {
    super(...arguments);
    // 显示弹窗参数
    this.dialogVisible = false;
    // 用户信息提交Service
    this.accountInfoDialogService = new AccountInfoDialogService();
    // 用户信息弹窗类型
    this.accountInfoDialogType = AccountInfoDialogType;
    // 弹窗状态
    this.type = AccountInfoDialogType.UNKNOWN;
  }
  // 打开弹窗
  open(type) {
    this.dialogVisible = true;
    this.type = type;
    this.accountInfoDialogService.getType(this.type);
    this.$nextTick(() => {
      var _a, _b, _c, _d, _e;
      if (this.type === AccountInfoDialogType.CHANGE_EMAIL) {
        this.accountInfoDialogService.formController.email = (_a = this.accountInfoDialogService.accountInfo) == null ? void 0 : _a.account;
      }
      if (this.type === AccountInfoDialogType.CHANGE_CB) {
        this.accountInfoDialogService.formController.email = (_b = this.accountInfoDialogService.accountInfo) == null ? void 0 : _b.cbAccount;
      }
      if (this.type === AccountInfoDialogType.CHANGE_PAY_PAL) {
        this.accountInfoDialogService.formController.email = (_c = this.accountInfoDialogService.accountInfo) == null ? void 0 : _c.paypalEmail;
      }
      if (this.type === AccountInfoDialogType.SWITCH_LANGUAGE) {
        this.accountInfoDialogService.formController.language = (_e = (_d = this.accountInfoDialogService.accountInfo) == null ? void 0 : _d.language) != null ? _e : "";
      }
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
    this._form._form.resetFields();
    this.accountInfoDialogService.formController = new UiFormController();
  }
  // 验证邮箱
  validateEmail() {
    this._form._form.validateField("email", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 提交表单
  submit() {
    return __async(this, null, function* () {
      const next = yield this.accountInfoDialogService.submit(this.type);
      if (next) {
        yield this[next]();
      }
    });
  }
  // 绑定邮箱账号
  bindEmail() {
    return __async(this, null, function* () {
      try {
        yield this.accountInfoDialogService.bindEmail();
        this.close();
        yield this.$router.push("/auth");
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.accountInfoDialogService.formController.status = UiFormStatus.COMPLETE;
      }
    });
  }
  // 绑定Paypal邮箱
  bindPaypalEmail() {
    return __async(this, null, function* () {
      try {
        yield this.accountInfoDialogService.bindPaypalEmail();
        this.$emit("changePaypalEmail", this.accountInfoDialogService.formController.email);
        this.close();
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.accountInfoDialogService.formController.status = UiFormStatus.COMPLETE;
      }
    });
  }
  // 表单验证
  validateField(prop) {
    this._form._form.validateField(prop);
  }
  // 修改密码
  changePassword() {
    return __async(this, null, function* () {
      try {
        yield this.accountInfoDialogService.changePassword();
        this.close();
        yield this.$router.push("/auth");
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.accountInfoDialogService.formController.status = UiFormStatus.COMPLETE;
      }
    });
  }
  // 语言修改
  switchLanguage() {
    return __async(this, null, function* () {
      try {
        yield this.accountInfoDialogService.switchLanguage();
        location.reload();
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.accountInfoDialogService.formController.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
__decorateClass([
  Ref("accountInfoForm")
], AccountInfoDialog.prototype, "_form", 2);
AccountInfoDialog = __decorateClass([
  Component({
    components: {
      SwitchLanguageForm,
      UiForm,
      EmailForm,
      CBForm,
      PayPalForm,
      PasswordForm
    }
  })
], AccountInfoDialog);
export {
  AccountInfoDialog as default
};
