"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class OrderApi {
  /**
   * 计算订单价格
   * @param vo
   */
  computePrice(vo) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/order-price-compute`,
        {
          params: vo
        }
      );
      return response.data;
    });
  }
  /**
   * 创建订单
   * @param vo
   */
  buildOrder(vo) {
    return __async(this, null, function* () {
      const response = yield API.post(
        `api://base.auth/v1/orders`,
        vo
      );
      return response.data;
    });
  }
  /**
   * 创建动态订单
   */
  buildDynamicOrder(vo) {
    return __async(this, null, function* () {
      const response = yield API.post(
        `api://base.auth/v1/dynamic-house`,
        vo
      );
      return response.data;
    });
  }
  /**
   * 创建动态续费订单
   */
  buildDynamicRenewOrder(vo) {
    return __async(this, null, function* () {
      const response = yield API.post(`api://base.auth/v1/products-renew-dynamic`, vo);
      return response.data;
    });
  }
  /**
   * 完成订单
   * @param orderId
   */
  completeOrder(orderId) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/orders/${orderId}/complete`
      );
    });
  }
  /**
   * 取消订单
   * @param orderId
   */
  cancelOrder(orderId) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/orders/${orderId}/cancel`
      );
    });
  }
  /**
   * 订单详情
   * @param orderId
   */
  getOrderDetail(orderId) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/orders/${orderId}`
      );
      return response.data;
    });
  }
  /**
   * 补充订单信息
   * 
   */
  extraOrderDetail(orderId, extraOrderDto) {
    return __async(this, null, function* () {
      return yield API.put(`api://base.auth/v1/orders/${orderId}/supplement`, {
        payWalletAddress: extraOrderDto.payWalletAddress,
        txHash: extraOrderDto.txHash
      });
    });
  }
}
export const orderApi = new OrderApi();
