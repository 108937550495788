"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { orderApi } from "@/shared/api/payment/order.api";
export class OrderService {
  /**
   * 计算价格
   * @param vo
   */
  computePrice(vo) {
    return __async(this, null, function* () {
      return yield orderApi.computePrice(vo);
    });
  }
  /**
   * 创建订单
   * @param vo
   */
  buildOrder(vo) {
    return __async(this, null, function* () {
      return yield orderApi.buildOrder(vo);
    });
  }
  /**
   * 创建动态住宅订单
   */
  buildDynamicOrder(vo) {
    return __async(this, null, function* () {
      return yield orderApi.buildDynamicOrder(vo);
    });
  }
  /**
   * 创建动态住宅续费订单
   */
  buildDynamicRenewOrder(vo) {
    return __async(this, null, function* () {
      return yield orderApi.buildDynamicRenewOrder(vo);
    });
  }
  /**
   * 完成订单
   * @param orderId
   */
  completeOrder(orderId) {
    return __async(this, null, function* () {
      yield orderApi.completeOrder(orderId);
    });
  }
  /**
   * 取消订单
   * @param orderId
   */
  cancelOrder(orderId) {
    return __async(this, null, function* () {
      yield orderApi.cancelOrder(orderId);
    });
  }
  /**
   * 订单详情
   * @param orderId
   */
  getOrderDetail(orderId) {
    return __async(this, null, function* () {
      return yield orderApi.getOrderDetail(orderId);
    });
  }
  extraOrderConfirm(orderId, requestOrderDto) {
    return __async(this, null, function* () {
      return yield orderApi.extraOrderDetail(orderId, requestOrderDto);
    });
  }
}
export var OrderPriceType = /* @__PURE__ */ ((OrderPriceType2) => {
  OrderPriceType2["UNKNOWN"] = "";
  OrderPriceType2["newProxy"] = "\u65B0\u8D2D\u4EE3\u7406";
  return OrderPriceType2;
})(OrderPriceType || {});
export var OrderType = /* @__PURE__ */ ((OrderType2) => {
  OrderType2["UNKNOWN"] = "";
  OrderType2["topUp"] = "recharge";
  OrderType2["newPurchase"] = "new_purchase";
  OrderType2["renewal"] = "renewal";
  OrderType2["trafficPackage"] = "traffic_package";
  OrderType2["refund"] = "refund";
  OrderType2["DYNAMIC"] = "dynamic";
  return OrderType2;
})(OrderType || {});
export var PayPlatformType = /* @__PURE__ */ ((PayPlatformType2) => {
  PayPlatformType2["UNKNOWN"] = "";
  PayPlatformType2["coin"] = "coin";
  PayPlatformType2["PayPal"] = "pay_pal";
  PayPlatformType2["alipay"] = "alipay";
  PayPlatformType2["FPX"] = "fpx";
  PayPlatformType2["TOUCH_N_GO"] = "touch_n_go";
  PayPlatformType2["GRAB_PAY"] = "grab_pay";
  PayPlatformType2["PIX"] = "pix";
  PayPlatformType2["VIETQR"] = "vietqr";
  PayPlatformType2["PROMPT_PAY"] = "prompt_pay";
  PayPlatformType2["G_CASH"] = "g_cash";
  PayPlatformType2["TRUE_MONEY"] = "true_money";
  PayPlatformType2["STRIP"] = "strip";
  PayPlatformType2["WE_CHAT"] = "we_chat";
  return PayPlatformType2;
})(PayPlatformType || {});
