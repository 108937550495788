"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
const header_icon = require("@/assets/images/index/faq-question.png");
const qa_icon = require("@/assets/images/index/question-and-answer.png");
const logo_pay_icon = require("@/assets/svgs/logo_pay.svg");
let FAQ = class extends Vue {
  constructor() {
    super(...arguments);
    this.headerIcon = header_icon;
    this.qaIcon = qa_icon;
    this.logoPay = logo_pay_icon;
  }
  created() {
    return __async(this, null, function* () {
    });
  }
  mounted() {
    return __async(this, null, function* () {
    });
  }
};
FAQ = __decorateClass([
  Component({
    components: {}
  })
], FAQ);
export {
  FAQ as default
};
