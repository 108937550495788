"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import menu from "@/shared/menu-config";
import { authAPI } from "@/data/api/auth/auth.api";
import { AccountInfoService } from "@/shared/services/account-info/account-info.service";
import { accountManagementApi } from "@/data/api/account-management/account-management.api";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { Debounce } from "vue-debounce-decorator";
let Layout = class extends Vue {
  constructor() {
    super(...arguments);
    this.routerPath = this.$route.path;
    this.menuactive = "";
    this.menu = menu;
    this.showPopover = sessionStorage.getItem("showPop") ? false : true;
    this.accountInfo = AccountInfoService.getAccountInfo();
    // 当前的语言
    this.nowLang = "";
  }
  created() {
    return __async(this, null, function* () {
      if (this.$route.meta.menuactive) {
        this.menuactive = String(this.$route.meta.menuactive);
      }
    });
  }
  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang) {
      this.nowLang = lang;
    } else {
      localStorage.setItem("lang", "en-US");
      this.nowLang = "en-US";
    }
  }
  // 跳转Telegram
  toTelegram() {
    window.open("https://t.me/iprockets01", "_blank");
  }
  changeLang() {
    return __async(this, null, function* () {
      var _a;
      try {
        localStorage.setItem("lang", this.nowLang);
        localStorage.removeItem("follow-brower-lang");
        this.$root.$i18n.locale = this.nowLang;
        yield accountManagementApi.switchLanguage({
          language: this.nowLang
        });
        document.title = this.$t(`route.${(_a = this.$route.meta) == null ? void 0 : _a.title}`);
        this.accountInfo.language = this.nowLang;
        location.reload();
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  //登出
  logOut() {
    this.$confirm(
      this.$t("auth.log_out.message"),
      this.$t("components.message_box.title"),
      {
        confirmButtonText: this.$t("components.message_box.confirm"),
        cancelButtonText: this.$t("components.message_box.cancel"),
        roundButton: true
      }
    ).then(() => __async(this, null, function* () {
      yield authAPI.logOut();
      localStorage.removeItem("token");
      yield this.$router.push("/auth");
    })).catch(() => {
      console.log("error");
    });
  }
  changePopover() {
    const show = sessionStorage.getItem("showPop");
    if (!show) {
      sessionStorage.setItem("showPop", "true");
    }
  }
  goBack() {
    this.$router.go(-1);
  }
  goToHelp() {
    window.open("https://www.iprockets.net/help");
  }
  goToCostCenter() {
    if (this.$route.path !== "/admin/cost") {
      this.$router.push("/admin/cost");
    }
  }
};
__decorateClass([
  Debounce(400)
], Layout.prototype, "changeLang", 1);
Layout = __decorateClass([
  Component({
    components: {}
  })
], Layout);
export {
  Layout as default
};
