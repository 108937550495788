"use strict";
import "./configuration";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/lang";
import ElementUI from "element-ui";
import "@fortawesome/fontawesome-free/css/all.min.css";
import dayjs from "dayjs";
import "./styles.scss";
import "./assets/style/theme/index.css";
import "./assets/style/main.scss";
import VueQuillEditor from "vue-quill-editor";
import Clipboard from "v-clipboard";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueGtag from "vue-gtag";
Vue.use(VueQuillEditor);
Vue.use(Clipboard);
Vue.use(VueGtag, {
  config: {
    id: "G-1X6367T7RJ",
    params: {
      send_page_view: true
    }
  },
  includes: [{
    id: "AW-11251063586",
    params: {
      send_page_view: false
    }
  }]
});
Vue.config.productionTip = false;
Vue.prototype.$dayjs = dayjs;
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
