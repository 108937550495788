"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue, PropSync } from "vue-property-decorator";
import { LanguageController } from "../../services/account-info-dialog.service";
let SwitchLanguageForm = class extends Vue {
  switchLanguage() {
    this.$emit("switchLanguage");
  }
};
__decorateClass([
  PropSync(
    "controller",
    { type: LanguageController, default: null }
  )
], SwitchLanguageForm.prototype, "_controller", 2);
SwitchLanguageForm = __decorateClass([
  Component({
    components: {}
  })
], SwitchLanguageForm);
export {
  SwitchLanguageForm as default
};
