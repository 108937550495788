"use strict";
const menu = [
  {
    title: "buying_proxy",
    to: "/admin/payment",
    index: "1",
    logo: require("../assets/images/payment.png"),
    logoActive: require("../assets/images/payment_ac.png")
  },
  {
    title: "buying_app",
    to: "/admin/app_pay",
    index: "6",
    logo: require("../assets/images/payment.png"),
    logoActive: require("../assets/images/payment_ac.png")
  },
  {
    title: "proxy_management",
    to: "/admin/proxy",
    index: "2",
    logo: require("../assets/images/proxy.png"),
    logoActive: require("../assets/images/proxy_ac.png")
  },
  {
    title: "app_management",
    to: "/admin/app",
    index: "5",
    logo: require("../assets/images/proxy.png"),
    logoActive: require("../assets/images/proxy_ac.png")
  },
  {
    title: "cost_center",
    to: "/admin/cost",
    index: "3",
    logo: require("../assets/images/cost.png"),
    logoActive: require("../assets/images/cost_ac.png")
  },
  {
    title: "account_settings",
    to: "/admin/account",
    index: "4",
    logo: require("../assets/images/account.png"),
    logoActive: require("../assets/images/account_ac.png")
  }
  // {
  // 	title: "浏览器集成",
  // 	to: "/browser_integration",
  // 	index: "5",
  // 	logo: require("../assets/images/browser.png"),
  // 	logoActive: require("../assets/images/browser_ac.png"),
  // }
];
export default menu;
