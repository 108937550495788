var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.renewService.renewConfig.type)?_c('div',{staticClass:"renew-wrap"},[(_vm.renewService.renewConfig.type === 'normal')?_c('div',{staticClass:"main-page-content renew-proxy-wrap"},[_c('div',{class:['renew-proxy', { 'not-panel': !_vm.panel }]},[_c('div',{staticClass:"renew-proxy-title"},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(_vm.$t("product.config.type")))]),_c('el-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.$t("product.config.region"))+"/"+_vm._s(_vm.$t("product.config.area")))]),_c('el-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.$t("product.config.category")))]),_c('el-col',{attrs:{"span":5}},[_vm._v(_vm._s(_vm.$t("product.config.protocol")))]),_c('el-col',{attrs:{"span":5}},[_vm._v("IP")])],1)],1),_vm._l((_vm.renewService.renewProduct.proxies),function(proxy,index){return _c('div',{key:index,staticClass:"renew-proxy-content"},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(proxy.type))]),_c('el-col',{attrs:{"span":5}},[_vm._v(_vm._s(proxy.region))]),_c('el-col',{attrs:{"span":5}},[_c('el-tag',{attrs:{"size":"mini"}},[_vm._v(_vm._s(proxy.category))])],1),_c('el-col',{attrs:{"span":5}},[_vm._v(_vm._s(proxy.protocol))]),_c('el-col',{attrs:{"span":5}},[_vm._v(_vm._s(proxy.exportIp?proxy.exportIp:'-'))])],1)],1)})],2),(
        _vm.renewService.renewProduct.proxies &&
        _vm.renewService.renewProduct.proxies.length > 1
      )?_c('div',{staticClass:"renew-proxy-btn",on:{"click":function($event){_vm.panel = !_vm.panel}}},[_c('i',{class:[
          { 'el-icon-arrow-down': !_vm.panel },
          { 'el-icon-arrow-up': _vm.panel },
        ]})]):_vm._e()]):_vm._e(),(_vm.renewService.renewConfig.type === 'dynamic')?_c('div',{staticClass:"main-page-content renew-proxy-wrap"},[_c('div',{class:['renew-proxy', { 'not-panel': !_vm.panel }]},[_c('div',{staticClass:"renew-proxy-title"},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(_vm.$t("product.config.type")))]),_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(_vm.$t("product.config.region"))+"/"+_vm._s(_vm.$t("product.config.area")))]),_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(_vm.$t("product.config.ip_type")))])],1)],1),_vm._l((_vm.renewService.renewDynamicProduct.proxies),function(proxy,index){return _c('div',{key:index,staticClass:"renew-proxy-content"},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(proxy.protocol))]),(proxy.dynamicProxyMessages !== null)?_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(proxy.dynamicProxyMessages.region ? proxy.dynamicProxyMessages.region : "")+_vm._s(proxy.dynamicProxyMessages.isp ? "/" + proxy.dynamicProxyMessages.isp : "/" + _vm.$t("product.config.isp_random"))+_vm._s(proxy.dynamicProxyMessages.chau ? "/" + proxy.dynamicProxyMessages.chau : "/" + _vm.$t("product.config.isp_random"))+_vm._s(proxy.dynamicProxyMessages.city ? "/" + proxy.dynamicProxyMessages.city : "/" + _vm.$t("product.config.isp_random")))]):_vm._e(),(proxy.dynamicProxyMessages === null)?_c('el-col',{attrs:{"span":8}},[_vm._v(" - ")]):_vm._e(),(proxy.dynamicProxyMessages !== null)?_c('el-col',{attrs:{"span":8}},[_c('el-tag',{attrs:{"size":"mini"}},[_vm._v(_vm._s(proxy.dynamicProxyMessages.dynamicTypeName))])],1):_vm._e(),(proxy.dynamicProxyMessages === null)?_c('el-col',{attrs:{"span":8}},[_vm._v(" - ")]):_vm._e()],1)],1)})],2),(
        _vm.renewService.renewDynamicProduct.proxies &&
        _vm.renewService.renewDynamicProduct.proxies.length > 1
      )?_c('div',{staticClass:"renew-proxy-btn",on:{"click":function($event){_vm.panel = !_vm.panel}}},[_c('i',{class:[
          { 'el-icon-arrow-down': !_vm.panel },
          { 'el-icon-arrow-up': _vm.panel },
        ]})]):_vm._e()]):_vm._e(),(_vm.renewService.renewConfig.type === 'dynamic')?_c('div',{staticClass:"main-page-content renew-period-wrap"},[_c('div',{staticClass:"payment-options-item"},[_c('div',{staticClass:"payment-options-item_title"},[_vm._v(" "+_vm._s(_vm.$t("product.config.type"))+" ")]),_c('div',{staticClass:"payment-options-item_content"},[_c('el-radio-group',{attrs:{"size":"small"},on:{"change":_vm.changeType},model:{value:(_vm.renewType),callback:function ($$v) {_vm.renewType=$$v},expression:"renewType"}},_vm._l((_vm.renewService.renewDynamicProduct.types),function(type,index){return _c('el-radio-button',{key:index,attrs:{"label":type}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)],1)]),_c('div',{staticClass:"payment-options-item"},[_c('div',{staticClass:"payment-options-item_title"},[_vm._v(" "+_vm._s(_vm.$t("product.config.dynamic_product"))+" ")]),_c('div',{staticClass:"payment-options-item_content payment-options-product"},[_c('el-radio-group',{attrs:{"size":"small"},on:{"change":_vm.changeProduct},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}},_vm._l((_vm.renewService.renewDynamicProduct
              .products),function(product,index){return _c('el-radio-button',{key:index,staticClass:"product_list_radio_button",attrs:{"label":product}},[_vm._v(" "+_vm._s(product.name)+" "),_c('div',{staticClass:"category_price"},[_vm._v(" "+_vm._s(product.description)+" ")])])}),1)],1)])]):_vm._e(),(_vm.renewService.renewConfig.type === 'normal')?_c('div',{staticClass:"main-page-content renew-period-wrap"},[_c('div',{staticClass:"payment-options-item"},[_c('div',{staticClass:"payment-options-item_title"},[_vm._v(" "+_vm._s(_vm.$t("product.config.period"))+" ")]),_c('div',{staticClass:"payment-options-item_content"},[_c('el-radio-group',{attrs:{"size":"small"},on:{"change":_vm.computePrice},model:{value:(_vm.renewService.renewConfig.period),callback:function ($$v) {_vm.$set(_vm.renewService.renewConfig, "period", $$v)},expression:"renewService.renewConfig.period"}},_vm._l((_vm.renewService.renewProduct.periods),function(period,index){return _c('el-radio-button',{key:index,attrs:{"label":period}},[_vm._v(" "+_vm._s(period.name)+" ")])}),1)],1)])]):_vm._e(),_c('payment-cost',{ref:"payment-cost",attrs:{"payment-cost":_vm.renewService.paymentCost},on:{"buildOrder":_vm.buildOrder}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }