"use strict";
import i18n from "@/lang";
export class PasswordFormService {
  /**
   * 确认密码检验
   * @param value
   * @param newPassword
   * @param callback
   */
  checkRepeatPassword(value, newPassword, callback) {
    if (value && value !== newPassword) {
      callback(new Error(i18n.t("account.password.change.password_do_not_match")));
    }
    callback();
  }
}
