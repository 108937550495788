"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { OrderService } from "@/shared/services/payment/order.service";
import { proxyManagementApi } from "@/data/api/proxy-management/proxy-management.api";
export class RenewService {
  constructor() {
    /// 订单相关
    this.orderService = new OrderService();
    /// 续费列表
    this.renewProduct = {
      periods: [],
      proxies: []
    };
    /// 动态续费列表
    this.renewDynamicProduct = {
      types: [],
      products: [],
      proxies: []
    };
    /// 续费配置
    this.renewConfig = {
      period: {},
      proxyIds: [],
      type: "normal"
    };
    /// 价格
    this.paymentCost = {
      loading: true,
      nowPrice: 0,
      originPrice: 0
    };
  }
  search() {
    return __async(this, null, function* () {
      try {
        if (this.renewConfig.type === "normal") {
          this.renewProduct = yield proxyManagementApi.getRenewList(this.renewConfig.proxyIds);
          this.renewConfig.period = this.renewProduct.periods[0];
        }
        if (this.renewConfig.type === "dynamic") {
          this.renewDynamicProduct = yield proxyManagementApi.getDynamicRenewList(this.renewConfig.proxyIds);
          this.renewConfig.renewType = this.renewDynamicProduct.types[0];
          this.renewConfig.product = this.renewDynamicProduct.products[0];
        }
      } catch (error) {
        throw error;
      }
    });
  }
  /**
   * 计算价格
   */
  computePrice() {
    return __async(this, null, function* () {
      var _a;
      this.paymentCost.loading = true;
      if (this.renewConfig.type === "normal") {
        const orderPrice = yield this.orderService.computePrice({
          type: "renewal",
          renew: {
            period: this.renewConfig.period.period,
            periodUnit: this.renewConfig.period.periodUnit,
            proxyIds: this.renewConfig.proxyIds.join(",")
          }
        });
        this.paymentCost.nowPrice = orderPrice.nowPrice;
        this.paymentCost.originPrice = orderPrice.originPrice;
        this.paymentCost.loading = false;
      }
      if (this.renewConfig.type === "dynamic") {
        const orderPrice = yield this.orderService.computePrice({
          type: "renewal",
          productId: (_a = this.renewConfig.product) == null ? void 0 : _a.id,
          renew: {
            period: null,
            periodUnit: null,
            proxyIds: this.renewConfig.proxyIds.join(",")
          }
        });
        this.paymentCost.nowPrice = orderPrice.nowPrice;
        this.paymentCost.originPrice = orderPrice.originPrice;
        this.paymentCost.loading = false;
      }
    });
  }
  /**
   * 创建订单
   */
  buildOrder() {
    return __async(this, null, function* () {
      this.paymentCost.loading = true;
      let orderInfo = {
        orderNo: "",
        status: "",
        statusName: "",
        pay: {
          model: null,
          url: null
        }
      };
      if (this.renewConfig.type === "normal") {
        orderInfo = yield this.orderService.buildOrder({
          type: "renewal",
          platform: "coin",
          // productId: this.paymentController.productSelected.product!.id,
          // number: this.paymentController.productSelected.number,
          renew: {
            period: this.renewConfig.period.period,
            periodUnit: this.renewConfig.period.periodUnit,
            proxyIds: this.renewConfig.proxyIds.join(",")
          }
        });
      }
      if (this.renewConfig.type === "dynamic") {
        orderInfo = yield this.orderService.buildDynamicRenewOrder({
          type: "RENEW_DYNAMIC",
          platform: "coin",
          proxyIds: this.renewConfig.proxyIds.join(","),
          productId: this.renewConfig.product.id
          // number: this.paymentController.productSelected.number,
        });
      }
      this.paymentCost.loading = false;
      return orderInfo;
    });
  }
}
