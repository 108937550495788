"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x2) => x2.done ? resolve(x2.value) : Promise.resolve(x2.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import { Debounce } from "vue-debounce-decorator";
import { RegisterService } from "@/views/auth/services/register.service";
import { authAPI } from "@/data/api/auth/auth.api";
const vk = require("@/assets/images/main_page/vk.png");
const odn = require("@/assets/images/main_page/odn.png");
const fb = require("@/assets/images/main_page/FB.png");
const x = require("@/assets/images/main_page/x.png");
const ins = require("@/assets/images/main_page/Ins.png");
const Pinterest = require("@/assets/images/main_page/pinterest.jpg");
const tele = require("@/assets/images/main_page/tele.png");
const lineage = require("@/assets/svgs/lineage.svg");
const archeage = require("@/assets/svgs/archeage.svg");
const pokeGo = require("@/assets/svgs/poke_go.svg");
const wow = require("@/assets/svgs/wow.svg");
const pw = require("@/assets/svgs/pw.svg");
const wot = require("@/assets/svgs/wot.svg");
const steam = require("@/assets/svgs/steam.svg");
const ebay = require("@/assets/svgs/ebay.svg");
const amazon = require("@/assets/svgs/amazon.svg");
const teamviewer = require("@/assets/svgs/teamviewer.svg");
const apple = require("@/assets/svgs/iphone.svg");
const samsung = require("@/assets/svgs/samsung.svg");
const ps4 = require("@/assets/svgs/ps4.svg");
const xbox = require("@/assets/svgs/xbox.svg");
export class RegisterController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
    this.verifyCode = "";
    this.confirmPassword = "";
  }
}
let IPv4Page = class extends Vue {
  constructor() {
    super(...arguments);
    this.vk = vk;
    this.odn = odn;
    this.fb = fb;
    this.x = x;
    this.ins = ins;
    this.pt = Pinterest;
    this.tele = tele;
    this.lineage = lineage;
    this.archeage = archeage;
    this.pokeGo = pokeGo;
    this.wow = wow;
    this.pw = pw;
    this.wot = wot;
    this.steam = steam;
    this.ebay = ebay;
    this.amazon = amazon;
    this.teamviewer = teamviewer;
    this.apple = apple;
    this.samsung = samsung;
    this.ps4 = ps4;
    this.xbox = xbox;
    this.country1 = "usa";
    this.country2 = "mon";
    this.country3 = "usa";
    this.countryList = [
      {
        value: "usa",
        label: "USA(United States)",
        icon: "f-us"
      },
      {
        value: "mon",
        label: "Montenegro(Montenegro)",
        icon: "f-mon"
      }
    ];
    this.num1 = 1;
    this.num2 = 2;
    this.num3 = 3;
    this.period1 = "5";
    this.period2 = "60";
    this.period3 = "5";
    this.periods = [
      {
        value: "5",
        label: "5 days"
      },
      {
        value: "7",
        label: "7 days"
      },
      {
        value: "30",
        label: "30 days"
      },
      {
        value: "60",
        label: "60 days"
      }
    ];
    this.alwaysDisabled = true;
    // 注册表单Controller
    this.controller = new RegisterController();
    // 注册Service
    this.registerService = new RegisterService();
    // 注册账号校验规则
    this.registerAccountRules = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.email_required"),
        trigger: "blur"
      },
      {
        min: 6,
        max: 100,
        message: this.$root.$i18n.t("auth.info.email_length"),
        trigger: "blur"
      },
      {
        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        message: this.$root.$i18n.t("auth.info.email_legality"),
        trigger: "blur"
      }
    ];
    // 注册密码校验规则
    this.registerPasswordRules = [
      {
        required: true,
        message: this.$root.$i18n.t("auth.info.password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: this.$root.$i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: this.$root.$i18n.t("auth.info.password_length"),
        trigger: "blur"
      }
    ];
    // 注册验证码校验规则
    this.registerVerifyCodeRules = [
      {
        required: true,
        message: this.$root.$i18n.t(
          "components.verify_code.placeholder"
        ),
        trigger: "blur"
      },
      {
        min: 6,
        max: 6,
        message: this.$root.$i18n.t("components.verify_code.format"),
        trigger: "blur"
      }
    ];
    // 注册二次校验密码验证规则
    this.confirmPasswordRule = [
      {
        required: true,
        message: this.$root.$i18n.t(
          "auth.info.confirm_password_required"
        ),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: this.$root.$i18n.t("auth.info.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: this.$root.$i18n.t("auth.info.password_length"),
        trigger: "blur"
      },
      {
        validator: this.checkConfirmPassword,
        trigger: "blur"
      }
    ];
  }
  // // 选择同意提示条件
  // agreementRule: MyRuleItem[] = [
  //   {
  //     message: this.$root.$i18n.t(
  //       "main_page.main_form_agreement_rule"
  //     ) as string,
  //     validator: this.checkAgreement,
  //     trigger: "blur",
  //   },
  // ];
  // //校驗許可協議
  // checkAgreement(rule: any, value: any, callback?: any) {
  //   if (value === false) {
  //     callback(
  //       new Error(
  //         this.$root.$i18n.t("main_page.main_form_agreement_rule") as string
  //       )
  //     );
  //     return false;
  //   }
  //   callback();
  //   return true;
  // }
  // 校验邮箱账号验证规则
  validateEmail() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 校验二次验证密码一致性规则
  checkConfirmPassword(rule, value, callback) {
    return this.registerService.checkConfirmPassword(
      value,
      this.controller.password,
      callback
    );
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.controller.status = UiFormStatus.LOADING;
        const queryParam = location.search.substr(1);
        const data = {
          account: this.controller.account,
          password: this.controller.password,
          verifyCode: this.controller.verifyCode
        };
        if (queryParam) {
          data.queryParam = queryParam;
        }
        let cid = localStorage.getItem("_cid");
        if (cid && cid !== "") {
          data.clientId = cid;
        }
        yield authAPI.register(data);
        if (cid && cid !== "") {
          localStorage.removeItem("_cid");
        }
        this.$message.success(
          this.$root.$i18n.t("components.register_success")
        );
      } catch (error) {
        console.log(error);
      }
    });
  }
  jump() {
    window.open("https://www.iprockets.com/auth", "__blank");
  }
};
__decorateClass([
  Ref("registerForm")
], IPv4Page.prototype, "_form", 2);
__decorateClass([
  Debounce(400)
], IPv4Page.prototype, "submit", 1);
IPv4Page = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputPassword,
      InputVerifyCode
    }
  })
], IPv4Page);
export {
  IPv4Page as default
};
