"use strict";
import i18n from "@/lang";
export class TrafficManagementService {
  constructor() {
    /// 状态列表
    this.statusList = [
      {
        label: i18n.t("traffic.list.status.available"),
        value: "AVAILABLE"
      },
      {
        label: i18n.t("traffic.list.status.expiring"),
        value: "TOBE_EXPIRED"
      },
      {
        label: i18n.t("traffic.list.status.expired"),
        value: "EXPIRED"
      },
      {
        label: i18n.t("traffic.list.status.depleted"),
        value: "USEDUP"
      }
    ];
  }
  /**
   * 获取使用情况
   */
  getTraffic(row, type) {
    if (type && type === "used") {
      let user_balance = row.total - row.balance;
      if (user_balance <= 0) {
        return `0B`;
      }
      if (user_balance > 0 && user_balance <= 1024) {
        return `${parseFloat(user_balance.toFixed(2))}B`;
      }
      if (user_balance > 1024 && user_balance <= 1024 * 1024) {
        return `${parseFloat((user_balance / 1024).toFixed(2))}KB`;
      }
      if (user_balance > 1024 * 1024 && user_balance <= 1024 * 1024 * 1024) {
        return `${parseFloat((user_balance / 1024 / 1024).toFixed(2))}MB`;
      }
      if (user_balance > 1024 * 1024 * 1024) {
        return `${parseFloat((user_balance / 1024 / 1024 / 1024).toFixed(2))}GB`;
      }
    } else {
      if (row.balance <= 0) {
        return `0Bytes`;
      }
      if (row.balance > 0 && row.balance <= 1024) {
        return `${parseFloat(row.balance.toFixed(2))}Bytes`;
      }
      if (row.balance > 1024 && row.balance <= 1024 * 1024) {
        return `${parseFloat((row.balance / 1024).toFixed(2))}KB`;
      }
      if (row.balance > 1024 * 1024 && row.balance <= 1024 * 1024 * 1024) {
        return `${parseFloat((row.balance / 1024 / 1024).toFixed(2))}MB`;
      }
      if (row.balance > 1024 * 1024 * 1024) {
        return `${parseFloat((row.balance / 1024 / 1024 / 1024).toFixed(2))}GB`;
      }
    }
  }
}
export var TrafficStatus = /* @__PURE__ */ ((TrafficStatus2) => {
  TrafficStatus2["AVAILABLE"] = "available";
  TrafficStatus2["TOBE_EXPIRED"] = "expiring";
  TrafficStatus2["EXPIRED"] = "expired";
  TrafficStatus2["USEDUP"] = "depleted";
  return TrafficStatus2;
})(TrafficStatus || {});
