"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, PropSync, Vue } from "vue-property-decorator";
import { PaymentController } from "@/views/payment/services/payment.service";
import i18n from "@/lang";
let PaymentOptions = class extends Vue {
  constructor() {
    super(...arguments);
    this.dynamicISPDatas = [
      {
        "id": "null",
        "name": i18n.t("product.config.isp_random")
      }
    ];
    this.dynamicLoading = false;
    this.cascaderProps = {
      expandTrigger: "hover",
      multiple: false,
      emitPath: true,
      value: "id",
      label: "name",
      children: "childrens"
    };
    this.dynamicTypeNum = 0;
    this.dynamicTypeOptions = [
      {
        label: this.$i18n.t("product.config.ip_type_one_time"),
        id: "frequency"
      },
      {
        label: this.$i18n.t("product.config.ip_type_time_region"),
        id: "ageing"
      }
    ];
    this.dynamicTypeSelected = "frequency";
  }
  // 修改选中项
  changeConfig(configName, parentId) {
    return __async(this, null, function* () {
      var _a, _b;
      this._paymentController.changeConfig(configName, parentId);
      if (((_a = this._paymentController.productSelected.type) == null ? void 0 : _a.frontPageType) === "DYNAMIC") {
        this.dynamicTypeSelected = "frequency";
        this._paymentController.productSelected.dynamicType = "frequency";
        let description = this.isJSON((_b = this._paymentController.productSelected.type) == null ? void 0 : _b.description);
        if (description !== false) {
          this._paymentController.productSelected.type.description = description;
        }
        yield this.changeRegion(this._paymentController.productSelected.region);
        this.findDefualtSelector();
      }
      this.computePrice();
    });
  }
  //判断是否可序列化
  isJSON(str) {
    if (typeof str === "string") {
      try {
        let data = JSON.parse(str);
        return data;
      } catch (e) {
        return false;
      }
    } else {
      return str;
    }
  }
  //按周/省的列表筛选默认值
  findDefualtSelector() {
    if (this.dynamicISPDatas.length > 1) {
      let arr = [];
      this.findDefaultId(this.dynamicISPDatas[1], arr);
      this._paymentController.selectedDynamicData = arr;
    } else {
      this._paymentController.selectedDynamicData = ["null"];
    }
  }
  //默认值迭代器
  findDefaultId(data, arr) {
    arr.push(data.id);
    if (data.hasOwnProperty("childrens")) {
      this.findDefaultId(data.childrens[0], arr);
    }
  }
  //修改IP类型的时候赋予时效性默认值
  chageDynamicType(value) {
    var _a, _b;
    this._paymentController.productSelected.dynamicType = value;
    if (value === "ageing") {
      this.dynamicTypeNum = (_a = this._paymentController.productSelected.type) == null ? void 0 : _a.description.default;
      this._paymentController.productSelected.dynamicTime = (_b = this._paymentController.productSelected.type) == null ? void 0 : _b.description.default;
    } else {
      this._paymentController.productSelected.dynamicTime = 0;
    }
  }
  //修改IP类型参数
  changeIPType(num) {
    this._paymentController.productSelected.dynamicTime = num;
  }
  //修改动态选中的项
  changeRegion(id) {
    return __async(this, null, function* () {
      try {
        this.dynamicLoading = true;
        const datas = yield this._paymentController.changeDynamicConfig(id);
        if (datas === null) {
          this.dynamicISPDatas = [{
            "id": "null",
            "name": this.$t("product.config.isp_random")
          }];
          this.findDefualtSelector();
          return;
        }
        let arr = [{
          "id": "null",
          "name": this.$t("product.config.isp_random")
        }, ...datas];
        this.dynamicISPDatas = arr;
        this.findDefualtSelector();
      } catch (error) {
        console.log(error);
      } finally {
        this.dynamicLoading = false;
      }
    });
  }
  // 计算价格
  computePrice() {
    this.$emit("computePrice");
  }
};
__decorateClass([
  PropSync("paymentController", {
    default: function() {
      return new PaymentController();
    }
  })
], PaymentOptions.prototype, "_paymentController", 2);
PaymentOptions = __decorateClass([
  Component({
    components: {}
  })
], PaymentOptions);
export {
  PaymentOptions as default
};
