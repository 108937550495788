"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class AccountManagementApi {
  /**
   * 绑定邮箱
   * @param vo
   */
  bindEmail(vo) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/email`,
        vo
      );
    });
  }
  /**
   * 绑定PayPal邮箱
   * @param vo
   */
  bindPayPalEmail(vo) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/paypal-email`,
        vo
      );
    });
  }
  /**
   * 修改密码
   * @param vo
   */
  changePassword(vo) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/account-password`,
        vo
      );
    });
  }
  /**
   * 切换语言
   * @param vo
   */
  switchLanguage(vo) {
    return __async(this, null, function* () {
      return yield API.put(
        `api://base.auth/v1/account-language`,
        {},
        {
          params: vo
        }
      );
    });
  }
}
export const accountManagementApi = new AccountManagementApi();
