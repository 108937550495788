"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import QrcodeVue from "qrcode.vue";
import CountDown from "./tools/CountDown.vue";
import { CostManagementService } from "../services/cost-management.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
let USDTPaymentConfigure_default = class extends Vue {
  constructor() {
    super(...arguments);
    this.payment_secret = "";
    this.payment_platform = "";
    this.payment_option_mounts = "0.0";
    this.payment_real_mounts = "0.0";
    this.payment_oder_number = "";
    this.payment_addressSegWit = "";
    this.real_platform = "";
    this.paymentHash = "";
    this.paymentWalletAddress = "";
    this.disabled = false;
    this.paymentSevice = new CostManagementService();
  }
  created() {
    return __async(this, null, function* () {
    });
  }
  mounted() {
    return __async(this, null, function* () {
      let html = document.querySelector("html");
      if (html) {
        html.style.margin = "0";
        html.style.padding = "0";
        html.style.fontSize = "16px";
        html.style.height = "auto";
        html.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;";
        html.style.backgroundColor = "#f4f5f9";
      }
      let app = document.querySelector("#app");
      if (app) {
        app.style.margin = "0";
        app.style.padding = "0";
        app.style.fontSize = "16px";
        app.style.height = "auto";
        app.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;";
        app.style.backgroundColor = "#f4f5f9";
      }
      if (this.$route.query.hasOwnProperty("p_a") && this.$route.query.hasOwnProperty("p_t") && this.$route.query.hasOwnProperty("p_op") && this.$route.query.hasOwnProperty("p_rp") && this.$route.query.hasOwnProperty("p_pt") && this.$route.query.hasOwnProperty("r_pt")) {
        this.payment_secret = this.$route.query.p_a;
        this.payment_platform = this.$route.query.p_t;
        this.payment_option_mounts = this.$route.query.p_op;
        this.payment_real_mounts = this.$route.query.p_rp;
        this.payment_oder_number = this.$route.query.p_pt;
        this.real_platform = this.$route.query.r_pt;
        if (this.payment_platform === "BITCOIN_BTC") {
          this.payment_addressSegWit = this.$route.query.p_sw;
        }
      } else {
        this.$router.push("/admin/cost");
      }
    });
  }
  copy(text) {
    return __async(this, null, function* () {
      try {
        yield navigator.clipboard.writeText(text);
        this.$message.success(
          this.$t("paymnet_configure.payment.copyMsg")
        );
      } catch (error) {
        console.log("Failed to copy:", error);
      }
    });
  }
  confirm() {
    return __async(this, null, function* () {
      try {
        yield this.paymentSevice.VirtualCurrencyPaymentConfirm(this.payment_oder_number, {
          txHash: this.paymentHash,
          payWalletAddress: this.paymentWalletAddress
        });
        this.$router.push("/admin");
      } catch (error) {
        throwErrorApi.printError(error);
      }
    });
  }
  cancel() {
    this.$router.go(-1);
  }
  timeout() {
    this.$router.go(-1);
  }
};
USDTPaymentConfigure_default = __decorateClass([
  Component({
    components: {
      QrcodeVue,
      CountDown
    }
  })
], USDTPaymentConfigure_default);
export {
  USDTPaymentConfigure_default as default
};
