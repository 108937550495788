"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import { PasswordFormService } from "@/views/accountManagement/services/password-form.service";
import { AccountInfoDialogType, PasswordController } from "@/views/accountManagement/services/account-info-dialog.service";
import i18n from "@/lang";
let PasswordForm = class extends Vue {
  constructor() {
    super(...arguments);
    this.passwordFormService = new PasswordFormService();
    this.accountInfoDialogType = AccountInfoDialogType;
    this.repeatPasswordRule = [
      {
        required: true,
        message: i18n.t("account.password.change.confirm_password_required"),
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        message: i18n.t("account.password.password_format"),
        trigger: "blur"
      },
      {
        min: 8,
        max: 16,
        message: i18n.t("account.password.password_length"),
        trigger: "blur"
      },
      {
        validator: this.checkRepeatPassword,
        trigger: "blur"
      }
    ];
  }
  validateField() {
    if (this._controller.repeatPassword) {
      this.$emit("validateField", "repeatPassword");
    }
  }
  checkRepeatPassword(rule, value, callback) {
    this.passwordFormService.checkRepeatPassword(value, this._controller.newPassword, callback);
  }
};
__decorateClass([
  PropSync(
    "controller",
    { type: PasswordController, default: null }
  )
], PasswordForm.prototype, "_controller", 2);
__decorateClass([
  Prop()
], PasswordForm.prototype, "type", 2);
PasswordForm = __decorateClass([
  Component({
    components: {
      InputPassword
    }
  })
], PasswordForm);
export {
  PasswordForm as default
};
