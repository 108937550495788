"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import UiForm, {
  UiFormStatus
} from "@/components/form/UiForm.vue";
import Login from "@/views/auth/views/form/Login.vue";
import Register from "@/views/auth/views/form/Register.vue";
import ForgetPassword from "@/views/auth/views/form/ForgetPassword.vue";
import { AuthService } from "@/views/auth/services/auth.service";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import { getLocale } from "@/lang";
let Auth = class extends Vue {
  constructor() {
    super(...arguments);
    // 登录、注册、忘记密码的Service
    this.authService = new AuthService();
    // 选择语言的列表
    this.langList = [
      {
        label: "English",
        value: "en-US"
      },
      {
        label: "\u7B80\u4F53\u4E2D\u6587",
        value: "zh-CN"
      }
      // ,
      // {
      //   "label":"Español",
      //   "value":"es-ES"
      // },
      // {
      //   "label":"繁體中文",
      //   "value":"zh-TW"
      // },
      // {
      //   "label":"Русский",
      //   "value":"ru-RU"
      // },
      // {
      //   "label":"Português",
      //   "value":"pt-PT"
      // },
      // {
      //   "label":"Tiếng Việt",
      //   "value":"vi-VN"
      // },
      // {
      //   "label":"ไทย",
      //   "value":"th-TH"
      // }
    ];
    // 默认选择的语言
    this.nowLang = "en-us";
    // 是否显示对应模块
    this.showBlock = true;
    // 显示弹出提示
    this.showChangeLangTip = false;
  }
  created() {
  }
  mounted() {
    this.nowLang = getLocale();
    if (this.$route.query.hasOwnProperty("authToken") && this.$route.query.authToken !== "") {
      localStorage.setItem("token", this.$route.query.authToken);
      this.$router.push("/admin");
    }
    const data = this.authService.checkRemember();
    this._loginController.receiveRememberPw(data);
    this.checkBrowserLang();
    if (this.$route.name && this.$route.name === "register") {
      this.authService.authType = "register";
    }
    if (this.$route.name && this.$route.name === "auth") {
      this.authService.authType = "login";
    }
  }
  // 检测浏览器语言
  checkBrowserLang() {
    const language = navigator.language;
    let now_lang = localStorage.getItem("lang");
    if (language === "zh-CN" && now_lang !== "zh-CN") {
      this.showChangeLangTip = true;
    }
  }
  // 确认切换中文
  confirmChangeZH() {
    this.showChangeLangTip = false;
    this.nowLang = "zh-CN";
    this.changeLang();
  }
  // 取消提示的显示
  cancelShowTip() {
    this.showChangeLangTip = false;
  }
  // 切换语言
  changeLang() {
    localStorage.setItem("lang", this.nowLang);
    localStorage.removeItem("follow-brower-lang");
    this.$root.$i18n.locale = this.nowLang;
    this.showBlock = false;
    setTimeout(() => {
      this.showBlock = true;
    }, 10);
  }
  // 切换登录页
  goToLogin() {
    this.authService.goToLogin();
    const data = this.authService.checkRemember();
    this._loginController.receiveRememberPw(data);
  }
  // 切换注册页
  goToRegister() {
    this.authService.goToRegister();
  }
  // 切换忘记密码页
  goToForgetPassword() {
    this.authService.goToForgetPassword();
  }
  // 注册表单提交
  toRegister(controller) {
    return __async(this, null, function* () {
      try {
        controller.status = UiFormStatus.LOADING;
        let flag = yield this.authService.register(controller);
        if (flag === true) {
          this.$gtag.event("conversion", {
            send_to: "AW-11251063586/l-YfCOXc9PAYEKK29vQp"
          });
          this.$router.push("/register_success");
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
  // 重置密码表单提交
  resetPassword(controller) {
    return __async(this, null, function* () {
      try {
        controller.status = UiFormStatus.LOADING;
        yield this.authService.resetPassword(controller);
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
  // 登录表单提交
  toLogin(controller) {
    return __async(this, null, function* () {
      try {
        controller.status = UiFormStatus.LOADING;
        yield this.authService.login(controller);
        yield this.$router.push("/admin/proxy");
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
__decorateClass([
  Ref("loginController")
], Auth.prototype, "_loginController", 2);
Auth = __decorateClass([
  Component({
    components: {
      UiForm,
      Login,
      Register,
      ForgetPassword
    }
  })
], Auth);
export {
  Auth as default
};
