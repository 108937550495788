"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import AccountInfoDialog from "@/views/accountManagement/views/dialog/AccountInfoDialog.vue";
import { AccountManagementService } from "@/views/accountManagement/services/account-management.service";
import { AccountInfoDialogType } from "@/views/accountManagement/services/account-info-dialog.service";
let AccountManagement = class extends Vue {
  constructor() {
    super(...arguments);
    // 用户信息处理Service
    this.accountManagementService = new AccountManagementService();
    // 弹窗类型
    this.accountInfoDialogType = AccountInfoDialogType;
  }
  created() {
    this.accountManagementService.accountInfo.password = "*********";
  }
  // 打开用户信息修改弹窗
  openAccountInfoDialog(type) {
    this._accountInfoDialog.open(type);
  }
  // 修改Paypal邮箱
  changePaypalEmail(email) {
    this.$forceUpdate();
  }
};
__decorateClass([
  Ref("accountInfoDialog")
], AccountManagement.prototype, "_accountInfoDialog", 2);
AccountManagement = __decorateClass([
  Component({
    components: {
      Layout,
      AccountInfoDialog
    }
  })
], AccountManagement);
export {
  AccountManagement as default
};
