"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { productApi } from "@/shared/api/payment/product.api";
import { OrderService } from "@/shared/services/payment/order.service";
import i18n from "@/lang";
export class CostManagementService {
  constructor() {
    /// 充值金额列表
    this.rechargeOptionList = [];
    /// 充值配置
    this.rechargeOption = {
      type: "topUp",
      product: {},
      platform: "alipay",
      number: 1,
      customize: false
    };
    /// 自定义充值金额
    this.rechargeAutoAmount = 1;
    /// 订单相关
    this.orderService = new OrderService();
    /// 订单类型列表
    this.typeList = [
      {
        label: i18n.t("order.type.recharge"),
        value: "topUp"
      },
      {
        label: i18n.t("order.type.new_purchase_proxy"),
        value: "newPurchase"
      },
      {
        label: i18n.t("order.type.renewal_proxy"),
        value: "renewal"
      },
      {
        label: i18n.t("order.type.traffic_package"),
        value: "trafficPackage"
      }
    ];
  }
  /**
   * 获取充值金额列表
   */
  getRechargeOption() {
    return __async(this, null, function* () {
      const response = yield productApi.getProduct({
        type: "coin"
      });
      this.rechargeOptionList = response.datas;
      this.rechargeOption.product = this.rechargeOptionList[0];
    });
  }
  /**
   * 修改选择项
   */
  changeProduct() {
    this.rechargeOption.number = 1;
    this.rechargeOption.customize = false;
  }
  /**
   * 是否自定义金额
   */
  changeCustomize() {
    this.rechargeOption.product = {};
    this.changeRechargeAutoAmount(this.rechargeAutoAmount);
    this.rechargeOption.customize = true;
  }
  /**
   * 修改自定义金额
   * @param value
   * 目前只支持整数，如果后期需要小数，则需要转换成对应的数量
   */
  changeRechargeAutoAmount(value) {
    this.rechargeOption.number = value;
  }
  /**
   * 修改支付方式
   * @param value
   */
  changePlatform(value) {
    var _a;
    if (value === "alipay") {
      if (this.rechargeOption.customize) {
        if (this.rechargeOption.number && this.rechargeOption.number > 1e3) {
          this.rechargeAutoAmount = 1e3;
          this.rechargeOption.number = 1e3;
        }
      } else {
        this.rechargeOption.product = (_a = this.rechargeOptionList.find((item) => {
          if (item.price && item.price <= 1e3) {
            return item;
          }
        })) != null ? _a : {};
      }
    }
  }
  /**
   * 获取可使用的支付方式
   */
  getPaymentMethods() {
    return __async(this, null, function* () {
      const methods = yield productApi.getPaymentMethods();
      return methods;
    });
  }
  /**
   * 创建订单
   */
  buildOrder() {
    return __async(this, null, function* () {
      var _a;
      const orderInfo = yield this.orderService.buildOrder({
        type: "topUp",
        productId: (_a = this.rechargeOption.product.id) != null ? _a : "",
        number: this.rechargeOption.number,
        customize: this.rechargeOption.customize,
        platform: this.rechargeOption.platform
      });
      return orderInfo;
    });
  }
  VirtualCurrencyPaymentConfirm(orderNo, requestOrderDto) {
    return __async(this, null, function* () {
      yield this.orderService.extraOrderConfirm(orderNo, requestOrderDto);
    });
  }
}
