"use strict";
export class TrafficDetailDialogService {
  /**
   * 获取使用情况
   */
  getTraffic(row) {
    if (row.deduct < 1024 * 1024) {
      return `${parseFloat((row.deduct / 1024).toFixed(2))}KB`;
    } else if (row.deduct < 1024 * 1024 * 1024) {
      return `${parseFloat((row.deduct / 1024 / 1024).toFixed(2))}MB`;
    } else {
      return `${parseFloat((row.deduct / 1024 / 1024 / 1024).toFixed(2))}GB`;
    }
  }
}
