"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
let PaymentSuccess = class extends Vue {
  constructor() {
    super(...arguments);
    this.payment_time_last = 5;
  }
  mounted() {
    return __async(this, null, function* () {
      this.timer_last();
    });
  }
  timer_last() {
    let timer_last = setInterval(() => {
      if (this.payment_time_last >= 0) {
        this.payment_time_last = this.payment_time_last - 1;
      }
      if (this.payment_time_last < 0) {
        this.$router.push("/admin");
        clearInterval(timer_last);
      }
    }, 1e3);
  }
  toAuth() {
    this.$router.push("/admin");
  }
};
PaymentSuccess = __decorateClass([
  Component({
    components: {}
  })
], PaymentSuccess);
export {
  PaymentSuccess as default
};
